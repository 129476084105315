import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {

  // let [hideLogo, setHideLogo] = useState(false);
  // useEffect(() => {
  //   try {
  //     var computerSN = document.computerSN;
  //     if (computerSN.length > 0) {
  //       setHideLogo(true);
  //     }
  //   } catch (e) { }
  // }, []);

  //return <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />;
  let bs = 2.5;
  return <Box style={{ borderRadius: 3, }} component="img" src="/static/Logo2.png" sx={{ width: 368 / bs, height: 64 / bs, ...sx }} />;
}
