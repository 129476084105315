import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext, useParams } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme, Box
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'


function Header({ numSelected, submit, loading = false, initialValues = {} }) {

    const LoginSchema = Yup.object().shape({
        Key: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            ...{
                Key: '',
            },
            ...initialValues
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            console.log('values', values);
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>

                            <Stack direction={'row'}>
                                <TextField
                                    type="text"
                                    label="内容"
                                    {...getFieldProps('Key')}
                                />
                                <Stack flex={1}></Stack>
                            </Stack>

                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function Index() {
    const [setTools] = useOutletContext();
    const theme = useTheme();
    console.log('theme', theme)
    let { number } = useParams();
    number = number ?? '';

    const TABLE_HEAD = [
        { label: '序号', id: 'Num' },
        { label: '预约单', id: 'VisitorOrder' },
        { label: '图片', id: 'Pics' },
        { label: '备注', id: 'Remark' },
        { label: '进/出', id: 'InOut' },
        { label: '时间', id: 'UpTime' },
        { label: '门卫', id: 'Guard' },
        /* { label: '操作', id: '', align: 'right' } */
    ];

    useEffect(() => {
        setTools([

        ]);
    }, []);

    return (
        <Card>
            <Pager url='/Web/Office/VisitorOrder/VisitorOrderInOutLogList' pageSize={50} params={{ Key: number }}>
                {({ pageData, handleChangePage, searchSubmit, loading }) => {
                    return (
                        <>
                            <Header initialValues={{ Key: number }} numSelected={0} submit={searchSubmit} loading={loading} />
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                    <TableCell align={headCell.align ?? 'inherit'}>
                                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pageData.data.map((row, index) => {
                                                const { id, In, Out, Pics, Remark, UpTime, VisitorOrderNumber, VisitorOrderType, Guard_NickName, Guard_Name } = row;
                                                return (
                                                    <TableRow hover>
                                                        <TableCell align="left">{index + 1}</TableCell>
                                                        <TableCell align="left">
                                                            <div dangerouslySetInnerHTML={{ __html: VisitorOrderNumber + '<br />' + VisitorOrderType }}></div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Stack direction={'row'} alignItems='center' sx={{ maxWidth: 200, flexWrap: 'wrap' }} >
                                                                {
                                                                    Pics.map((pic) => <Box mr={0.5} mb={0.5}><a target={'_blank'} href={pic}><img width={60} src={pic} /></a></Box>)
                                                                }
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ maxWidth: 150, }}>
                                                            {Remark}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {In && '进'}{Out && '出'}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div dangerouslySetInnerHTML={{ __html: UpTime.Format('yyyy-MM-dd<br/>hh:mm:ss') }}></div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div dangerouslySetInnerHTML={{ __html: Guard_NickName + '<br />' + Guard_Name }}></div>
                                                        </TableCell>
                                                        {/* <TableCell align="right">
                                                            <MoreMenu Sub={
                                                                [
                                                                       {
                                                                         label: '查看',
                                                                         icon: <Icon icon={editFill} width={24} height={24} />,
                                                                         to: `/dashboard/bicycle/list/detail/${id}`
                                                                     }, 
                                                                ]
                                                            } />
                                                        </TableCell> */}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={-1}
                                component="div"
                                count={pageData.totalCount}
                                rowsPerPage={pageData.pageSize}
                                page={pageData.pageIndex}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )
                }}
            </Pager>
        </Card >
    );
}
