import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem, Switch
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Field, Form, FieldArray, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function PzltParam() {
  const navigete = useNavigate();
  const [setTools] = useOutletContext();

  const loadData = async () => {
    var result = await request.GetData('Web/Office/ParkingSpace/GetParkingSpaceLTPrice', {})
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    setTools([]);
    loadData();
  }, [])

  const LoginSchema = Yup.object().shape({
    OpenLinTingCheWei: Yup.boolean().nullable(),
    PayedMaxLeaveMinutes: Yup.number().integer('必须为整数').required('最大提前预定分钟 必填'),

    FreeMinutes: Yup.number().integer('必须为整数').required('必填'),
    NewEnergyFreeMinutes: Yup.number().integer('必须为整数').required('必填'),

    //无入场记录 出场时收费多少
    NotInPayedPrice: Yup.number().integer('必须为整数').required('必填'),

    lt_FirstFewHours: Yup.number().integer('必须为整数').required('必填'),
    lt_FirstFewHoursPrice: Yup.number().required('必填'),
  });

  const formik = useFormik({
    initialValues: {
      OpenLinTingCheWei: null,
      PayedMaxLeaveMinutes: 15,

      FreeMinutes: 15,
      NewEnergyFreeMinutes: 15,

      NotInPayedPrice: 5,

      lt_FirstFewHours: 2,
      lt_FirstFewHoursPrice: 4,
      TimersPrice: [...new Array(24).keys()].map(i => ({ time: i, price: 1 })),

      _filePath: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = await request.PostJsonData('Web/Office/ParkingSpace/UpdateParkingSpaceLTPrice', data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      window.location.href = window.location.href;
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;
  // console.log('touched', touched)

  return (
    <Card>
      {/* <CardHeader title="公共车位" subheader="" /> */}
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <Stack direction={'row'} sx={{ width: '100%' }}  >
                <Stack spacing={3} alignItems={'flex-start'} sx={{ flex: 1 }}>
                  <Stack>
                    <FormControlLabel
                      control={
                        <Switch
                          name='OpenLinTingCheWei'
                          color="primary"
                          checked={values.OpenLinTingCheWei}
                          onChange={handleChange}
                        />
                      }
                      label="允许拍照临停"
                      labelPlacement="end"
                    />
                  </Stack>
                  <Stack spacing={2} direction={'row'}>
                    <TextField
                      autoComplete="PayedMaxLeaveMinutes"
                      type="number"
                      label="支付后多少分钟必须出场，否则费用重新计算"
                      {...getFieldProps('PayedMaxLeaveMinutes')}
                      error={Boolean(touched.PayedMaxLeaveMinutes && errors.PayedMaxLeaveMinutes)}
                      helperText={touched.PayedMaxLeaveMinutes && errors.PayedMaxLeaveMinutes}
                    />
                  </Stack>
                  <Stack spacing={2} direction={'row'}>
                    <TextField
                      autoComplete="FreeMinutes"
                      type="number"
                      label="燃油车可免费几分钟"
                      {...getFieldProps('FreeMinutes')}
                      error={Boolean(touched.FreeMinutes && errors.FreeMinutes)}
                      helperText={touched.FreeMinutes && errors.FreeMinutes}
                    />
                    <TextField
                      autoComplete="NewEnergyFreeMinutes"
                      type="number"
                      label="新能源车可免费几分钟"
                      {...getFieldProps('NewEnergyFreeMinutes')}
                      error={Boolean(touched.NewEnergyFreeMinutes && errors.NewEnergyFreeMinutes)}
                      helperText={touched.NewEnergyFreeMinutes && errors.NewEnergyFreeMinutes}
                    />
                  </Stack>
                  <Stack spacing={2} direction={'row'}>
                    <TextField
                      autoComplete="NotInPayedPrice"
                      type="number"
                      label="无入场记录收费多少元"
                      {...getFieldProps('NotInPayedPrice')}
                      error={Boolean(touched.NotInPayedPrice && errors.NotInPayedPrice)}
                      helperText={(touched.NotInPayedPrice && errors.NotInPayedPrice) || '0表示直接出场'}
                    />
                  </Stack>
                </Stack>
                <Stack direction={'column'} alignItems={'center'}>
                  <img style={{ height: 240 }} src={values._filePath} />
                  <div>拍照临停-停车场内提前缴费码</div>
                </Stack>
              </Stack>

              <Stack pt={2}>设置价格</Stack>

              <Stack direction={'row'} spacing={2}>
                <TextField
                  autoComplete="lt_FirstFewHours"
                  type="number"
                  label="前几个小时"
                  {...getFieldProps('lt_FirstFewHours')}
                  error={Boolean(errors.lt_FirstFewHours)}
                  helperText={touched.lt_FirstFewHours && errors.lt_FirstFewHours}
                />
                <TextField
                  autoComplete="lt_FirstFewHoursPrice"
                  type="number"
                  label="前几个小时价格"
                  {...getFieldProps('lt_FirstFewHoursPrice')}
                  error={Boolean(errors.lt_FirstFewHoursPrice)}
                  helperText={touched.lt_FirstFewHoursPrice && errors.lt_FirstFewHoursPrice}
                />
              </Stack>

              <Stack spacing={2} sx={{
                width: '100%', '>div': {
                  display: 'inline-grid', 'grid-template-columns': '33.33% 33.33% 33.33%', 'grid-row-gap': 10,
                  'grid-column-gap': 5
                }
              }}>
                {/* {[...new Array(24).keys()].map(_index => {
                  return (
                    <Stack spacing={2} direction={'row'}>
                      <Stack>{_index}时</Stack>
                      <Stack>
                        <TextField
                          autoComplete="NewEnergyFreeMinutes"
                          type="number"
                          label="新能源车免费几小时"
                          {...getFieldProps('NewEnergyFreeMinutes')}
                          error={Boolean(errors.NewEnergyFreeMinutes)}
                          helperText={errors.NewEnergyFreeMinutes ?? '填入0表示关闭新能源免费'}
                        />
                      </Stack>
                    </Stack>
                  )
                })} */}
                <FieldArray
                  name="TimersPrice"
                  render={arrayHelpers => {
                    console.log('TimersPrice', values.TimersPrice);
                    return (
                      <div>
                        {values.TimersPrice.map((tp, index) => (
                          <Stack key={index} direction={'row'} alignItems={'center'}>
                            <Stack sx={{ width: 50 }}>{values.TimersPrice[index].time}时</Stack>
                            <TextField type="number" {...getFieldProps(`TimersPrice.${index}.price`)} />
                            {/* <button type="button" onClick={() => arrayHelpers.remove(index)}>
                              -
                            </button> */}
                          </Stack>
                        ))}
                        {/* <button
                        type="button"
                        onClick={() => arrayHelpers.push({ name: '', age: '' })}
                      > + </button> */}
                      </div>
                    )
                  }}
                />
              </Stack>

              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                保存
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
