import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Paper,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function AddSort() {
  const theme = useTheme();
  console.log('theme', theme.palette.error.main)
  const navigete = useNavigate();
  const [setTools] = useOutletContext();
  let { id } = useParams();
  let add = id === undefined;

  const loadData = async () => {
    var result = await request.GetData('Web/Office/Product/GetOnePropertyProductSort?id=' + id, {})
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    if (!add) {
      setTools([
        {
          title: '删除',
          icon: 'carbon:delete',
          onClick: async () => {
            if (window.confirm('确定要删除吗？')) {
              const result = await request.PostJsonData('Web/Office/Product/DeletePropertyProductSort?id=' + id, {});
              if (result.Code === 1) {
                global.addMessage(result.Message);
                global.myHistory.back();
                return true;
              }
              else
                global.addMessage(result.Message);
              return false;
            }
          },
        }
      ]);
      loadData();
    }
  }, [])

  const LoginSchema = Yup.object().shape({
    Name: Yup.string().required('分类名称 必填'),
    Icon: Yup.string().required('图标 必填'),
    OrderNum: Yup.string().required('排序 必填'),
  });

  const formik = useFormik({
    initialValues: {
      Name: '',
      Icon: '',
      OrderNum: 0,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = add ?
      await request.PostJsonData('Web/Office/Product/CreatePropertyProductSort', data) :
      await request.PostJsonData('Web/Office/Product/EditPropertyProductSort?id=' + id, data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      //navigete('/dashboard/ParkingSpace/list', { replace: true });
      global.myHistory.back();
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <Card>
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <TextField
                autoComplete="Name"
                type="text"
                label="类型名称"
                {...getFieldProps('Name')}
                error={Boolean(touched.Name && errors.Name)}
                helperText={touched.Name && errors.Name}
              />


              {/* <Stack sx={{ position: 'relative' }}>
                <Paper variant="outlined" sx={Boolean(errors.Icon) ? { p: 2, borderColor: theme.palette.error.main } : { p: 2 }} >
                  <Stack alignItems={'center'} spacing={1.5}>
                    {!Boolean(errors.Icon) && values.Icon &&  <img src={values.Icon} style={{ width: 100, height: 100 }} />}
                    <TextField
                      autoComplete="Icon"
                      type="text"
                      label="图标"
                      {...getFieldProps('Icon')}
                      error={Boolean(touched.Icon && errors.Icon)}
                      helperText={touched.Icon && errors.Icon}
                      sx={{ display: 'none' }}
                    />
                    <Stack direction={'row'} spacing={1.5}>
                      <label htmlFor="contained-button-file">
                        <Input accept="image/*" id="contained-button-file" type="file" onChange={async (e) => {
                          if (e.target.files.length > 0) {
                            var result = await request.PostFile(e.target.files[0]);
                            if (result.Code === 1) {
                              formik.setFieldValue('Icon', result.Result.FilePath);
                            }
                          }
                        }} />
                        <Button variant="contained" component="span">
                          上传
                        </Button>
                      </label>
                      <Button color="grey" variant="contained" component="span" sx={{ mr: 2 }}
                        onClick={() => { formik.setFieldValue('Icon', ''); }}
                      >
                        清空
                      </Button>
                    </Stack>
                  </Stack>
                </Paper>
                <Typography variant='caption' px={1} sx={{ position: 'absolute', top: '-10px', left: '10px', paddingLeft: '5px', paddingRight: '5px', display: 'block', backgroundColor: '#FFF' }}>图标</Typography>
                {Boolean(errors.Icon) && <Stack ml={2} mt={0.5}><Typography color='error' variant='caption'>{errors.Icon}</Typography></Stack>}
              </Stack> */}
              <UpLoadInput title='图标' name='Icon' formik={formik} />


              <TextField
                autoComplete="OrderNum"
                type="number"
                label="排序"
                {...getFieldProps('OrderNum')}
                error={Boolean(touched.OrderNum && errors.OrderNum)}
                helperText={touched.OrderNum && errors.OrderNum}
              />

              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton

                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                保存
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
