import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import React, { useState, useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';

import { Routes, Route, useParams, useLocation } from 'react-router-dom';
import request from '../../request'

// ----------------------------------------------------------------------

export default function InputCompanyCode(props) {
  let { computerSN, successCallBack } = props;
  //console.log('computerSN', computerSN)

  const [_companyCode, set_CompanyCode] = useState(global.isDevelopment ? '' : '');

  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    companyCode: Yup.string().required('激活码必填'),
  });

  useEffect(() => {
    let localStorage_companyCode = reactLocalStorage.get('localStorage_companyCode', null);
    if (localStorage_companyCode != null && localStorage_companyCode.length > 0) {
      console.log('localStorage_companyCode', localStorage_companyCode);
      set_CompanyCode(localStorage_companyCode);
      submit(localStorage_companyCode)
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      companyCode: _companyCode,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      console.log('values', values);
      const { companyCode } = values;

      submit(companyCode).then(result => {
        if (!result) {
          actions.setSubmitting(false);
        }
      })
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const submit = async (companyCode) => {
    //computerSN 
    //companyCode
    const result = await request.PostFormData('User/VerificationPropertyCode', { computerSN, companyCode });
    console.log('VerificationPropertyCode Result', result);
    if (result.Code === 1) {
      reactLocalStorage.set('localStorage_companyCode', companyCode);
      //navigate('/login', { replace: true });
      //global.addMessage(result.Message);
      successCallBack && successCallBack(result.Result);
    }
    else
      global.addMessage(result.Message);
    return false;
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="companyCode"
            type="text"
            label="激活码"
            {...getFieldProps('companyCode')}
            error={Boolean(touched.companyCode && errors.companyCode)}
            helperText={touched.companyCode && errors.companyCode}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}
        <Stack sx={{ my: 2 }}></Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          立即验证
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
