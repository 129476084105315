import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Paper,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function BlackListAdd() {
  const theme = useTheme();
  // console.log('theme', theme.palette.error.main)
  const navigete = useNavigate();
  const [setTools] = useOutletContext();
  let { id } = useParams();
  let add = id === undefined;

  const loadData = async () => {
    var result = await request.GetData('Web/Office/LicensePlates_Blacklist/GetOne?id=' + id, {})
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    if (!add) {
      setTools([
        {
          title: '删除',
          icon: 'carbon:delete',
          onClick: async () => {
            if (window.confirm('确定要删除吗？')) {
              const result = await request.PostJsonData('Web/Office/LicensePlates_Blacklist/Delete?id=' + id, {});
              if (result.Code === 1) {
                global.addMessage(result.Message);
                global.myHistory.back();
                return true;
              }
              else
                global.addMessage(result.Message);
              return false;
            }
          },
        }
      ]);
      loadData();
    }
    else
      setTools([]);
  }, [])

  const LoginSchema = Yup.object().shape({
    LicensePlates: Yup.string().required('车牌 必填'),
    Remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      LicensePlates: '',
      Remark: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = add ?
      await request.PostJsonData('Web/Office/LicensePlates_Blacklist/Create', data) :
      await request.PostJsonData('Web/Office/LicensePlates_Blacklist/Edit?id=' + id, data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      global.myHistory.back();
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }
  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

  return (
    <Card>
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <TextField type="text" label="车牌"  {...getFieldProps('LicensePlates')} error={Boolean(touched.LicensePlates && errors.LicensePlates)} helperText={touched.LicensePlates && errors.LicensePlates} />
              <TextField type="text" label="备注"  {...getFieldProps('Remark')} error={Boolean(touched.Remark && errors.Remark)} helperText={touched.Remark && errors.Remark} />
              <Stack sx={{ my: 4 }}></Stack>
              <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>保存</LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
