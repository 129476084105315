import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Typography,
    Paper,
    Card, CardHeader,
    Avatar,
    Button, FormControl, Select, InputLabel, MenuItem, Switch,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput, getHelperAndErrorProps, RichText } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cnLocale from 'date-fns/locale/zh-CN';


export default function Detail() {
    const theme = useTheme();
    //console.log('theme', theme);
    const navigete = useNavigate();
    const [setTools] = useOutletContext();
    let { id } = useParams();
    const [data, setData] = useState(null);

    const loadData = async () => {
        var result = await request.GetData('Web/Office/Bicycle/BicycleOne?id=' + id, {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else {
            global.addMessage(result.Message);
        }
    }
    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (data != null)
            setTools([
                {
                    title: '查看记录',
                    icon: 'carbon:add',
                    path: `/dashboard/bicycle/inoutlog/${data.BicycleNumber}`,
                }
            ])
    }, [data])

    if (data == null) return null;

    let { BicycleNumber, BicycleType, BicyclePics, UpTime, NickName, Name, Mobile, JiDong, DanYuan, FangHao } = data;
    return (
        <Card>
            <Box p={3}>
                <Stack direction={'row'} pb={1}>
                    <Item flex={1} title={'单车编号'} content={BicycleNumber} />
                    <Item flex={1} title={'单车类型'} content={BicycleType} />
                    <Item flex={1} title={'更新时间'} content={UpTime} />
                </Stack>
                <Stack direction={'row'} pb={1}>
                    <Item flex={1} title={'业主'} content={(Name && NickName) + Mobile} />
                    <Item flex={1} title={'地址'} content={JiDong + DanYuan + FangHao} />
                    <Item flex={1} title={''} content={''} />
                </Stack>
                <Stack direction={'column'} pb={1}>
                    <Stack pr={2}><Typography variant='subtitle1'>{'图片'}</Typography></Stack>
                    <Stack direction={'row'} spacing={1}>
                        {BicyclePics.map(item => <a target={'_blank'} href={item}><img width='80' src={item} /></a>)}
                    </Stack>
                </Stack>
            </Box>
        </Card >
    );
}

const Item = (props) => {
    let { title, content } = props;
    return (
        <Stack direction={'row'} {...props}>
            <Stack pr={2}><Typography variant='subtitle2'>{title}</Typography></Stack>
            <Stack><Typography variant='body2'>{content}</Typography></Stack>
        </Stack>
    )
}