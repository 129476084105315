import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.Name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function EditOwner() {
  const navigete = useNavigate();
  const [setTools] = useOutletContext();
  let { id } = useParams();

  const loadData = async () => {
    var result = await request.GetData('Web/Office/Owner/GetOwner?id=' + id, {})
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    setTools([
      {
        title: '删除',
        icon: 'carbon:delete',
        onClick: async () => {
          if (window.confirm('确定要删除吗？')) {
            const result = await request.PostJsonData('Web/Office/Owner/DeleteOwner?id=' + id, {});
            if (result.Code === 1) {
              global.addMessage(result.Message);
              global.myHistory.back();
              return true;
            }
            else
              global.addMessage(result.Message);
            return false;
          }
        },
      },
      {
        title: '解除认证信息',
        // icon: 'carbon:delete',
        onClick: async () => {
          if (window.confirm('确定要解除认证信息吗？')) {
            const result = await request.PostJsonData('Web/Office/Owner/JieBangOwner?id=' + id, {});
            if (result.Code === 1) {
              global.addMessage(result.Message);
              global.myHistory.back();
              return true;
            }
            else
              global.addMessage(result.Message);
            return false;
          }
        },
      }
    ]);
    loadData();
  }, [])

  const LoginSchema = Yup.object().shape({
    Name: Yup.string().required('姓名 必填'),
    Mobile: Yup.string().required('手机 必填'),
    JiDong: Yup.string().required('栋数 必填'),
    DanYuan: Yup.string().required('单元 必填'),
    FangHao: Yup.string().required('房号 必填'),
    ElseCode: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      Name: '',
      Mobile: '',
      JiDong: '',
      DanYuan: '',
      FangHao: '',
      ElseCode: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = await request.PostJsonData('Web/Office/Owner/UpdateOwner?id=' + id, data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      //navigete('/dashboard/owner/list', { replace: true });
      global.myHistory.back();
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

  const Input = styled('input')({
    display: 'none',
  });

  //console.log('values', values)

  return (
    <Card>
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <TextField
                autoComplete="Name"
                type="text"
                label="姓名"
                {...getFieldProps('Name')}
                error={Boolean(touched.Name && errors.Name)}
                helperText={touched.Name && errors.Name}
              />
              <TextField
                autoComplete="Mobile"
                type="text"
                label="手机"
                {...getFieldProps('Mobile')}
                error={Boolean(touched.Mobile && errors.Mobile)}
                helperText={touched.Mobile && errors.Mobile}
              />
              <TextField
                autoComplete="JiDong"
                type="text"
                label="几栋"
                {...getFieldProps('JiDong')}
                error={Boolean(touched.JiDong && errors.JiDong)}
                helperText={touched.JiDong && errors.JiDong}
              />
              <TextField
                autoComplete="DanYuan"
                type="text"
                label="单元"
                {...getFieldProps('DanYuan')}
                error={Boolean(touched.DanYuan && errors.DanYuan)}
                helperText={touched.DanYuan && errors.DanYuan}
              />
              <TextField
                autoComplete="FangHao"
                type="text"
                label="房号"
                {...getFieldProps('FangHao')}
                error={Boolean(touched.FangHao && errors.FangHao)}
                helperText={touched.FangHao && errors.FangHao}
              />
              <TextField
                autoComplete="ElseCode"
                type="text"
                label="其它设备"
                helperText="多个设备用逗号分隔"
                {...getFieldProps('ElseCode')}
                error={Boolean(touched.ElseCode && errors.ElseCode)}
              // helperText={touched.ElseCode && errors.ElseCode}
              />

              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton

                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                保存
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
