import React from 'react';
import { Outlet, matchPath, useLocation, } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Link,
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../../components/Page';
import { SubMenus, TopRightTools } from '../MyComponents'

export default function OwnerLayout() {

  const [tools, setTools] = useState([]);

  const subMenus = [
    {
      title: '账单',
      icon: null,
      path: '/dashboard/collect/bill',
    },
    {
      title: '收款码',
      icon: null,
      path: '/dashboard/collect/qrCode',
    }
  ]

  return (
    <Page title="Set | Minimal-UI">
      <Container >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} sx={{ minHeight: 36 }}>
          <Box display={'flex'}>

            <SubMenus Menus={subMenus} />

          </Box>

          <TopRightTools Tools={tools} />

        </Stack>
        
        <Outlet context={[setTools]} />

      </Container>
    </Page>
  );
}
