import { Icon } from '@iconify/react';
import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Link as RouterLink, useOutletContext, useLocation, matchPath } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
// material
import {
    Paper,
    Box,
    Card,
    Table,
    Stack,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Button, IconButton, TableHead,
    Menu, MenuItem,
    ListItemIcon, ListItemText, TextField,
    useTheme,
    Divider
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';



export function SubMenus({ Menus }) {
    const theme = useTheme();
    const { pathname } = useLocation();
    const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

    const defaultClass = {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        //fontSize: 16,
        mr: 0.7,
        ml: 0.7,
        px: 0.6,
        pb: 0.6,
        '& svg': {
            fontSize: '1.15rem',
            pr: 0.5
        }
    }
    const activeClass = {
        ...defaultClass,
        position: 'relative',
        color: theme.palette.primary.main,
        '&:before': {
            height: 2,
            bottom: 0,
            right: 0,
            left: 0,
            content: "''",
            //display: 'none',
            position: 'absolute',
            //borderTopLeftRadius: 4,
            //borderTopRightRadius: 4,
            backgroundColor: theme.palette.primary.main
        }
    }
    const notActiveClass = {
        ...defaultClass,
        color: alpha(theme.palette.text.secondary, 0.3)
    }

    return (
        <>
            {
                Menus.map((item) => {
                    // console.log('item.title', item.title)
                    if (item.title === '-') { return <Divider orientation="vertical" flexItem />; }
                    else {
                        return (
                            <Box
                                to={item.path}
                                component={RouterLink}
                                sx={match(item.path) ? activeClass : notActiveClass}
                            >
                                {item.icon && <Icon icon={item.icon} />}
                                <Typography variant='subtitle1'>{item.title}</Typography>
                            </Box>
                        );
                    }
                })
            }
        </>
    );
}

export function TopRightTools({ Tools }) {
    return (
        <Stack direction={'row'} spacing={1}>
            {
                Tools.map((tool) => {
                    let props = tool.path == null ?
                        { onClick: tool.onClick }
                        :
                        { component: RouterLink, to: tool.path }
                    let disabled = tool.disabled == null ? false : tool.disabled;
                    return (
                        <Button
                            disabled={disabled}
                            variant="contained"
                            startIcon={tool.icon && <Icon icon={tool.icon} />}
                            {...props}
                            color={tool.color ? tool.color : 'primary'}
                        >
                            {tool.title}
                        </Button>
                    )
                })
            }
        </Stack>
    );
}

export function MoreMenu({ Sub }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    if (Sub == null || Sub.length < 1) return null;
    if (Sub.length == 1) {
        let { label, icon, to } = Sub[0];
        let props = Sub[0].to == null ?
            { onClick: Sub[0].onClick }
            :
            { component: RouterLink, to: Sub[0].to }
        return (
            <Button {...props} startIcon={icon}
                sx={{ color: 'text.secondary' }}
            >
                {label}
            </Button>
        )
    }
    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>
            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {Sub.map((sub) => {
                    const { label, icon, to, onClick } = sub;
                    let props = to == null ?
                        { onClick: onClick }
                        :
                        { component: RouterLink, to: to }
                    return (
                        <MenuItem {...props} sx={{ color: 'text.secondary' }}>
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2' }} />
                        </MenuItem>
                    )
                }
                )}
            </Menu>
        </>
    );
}

const _Pager = (props, ref) => {
    //方法暴露
    useImperativeHandle(
        ref,
        () => ({
            refresh() {//刷新
                loadData();
            },
        }),
        []
    );

    let { children, url, sourceParams = {}, params = {}, pageIndex = 0, pageSize = 20 } = props;

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState({ data: [] });
    const [pageExpandData, setPageExpandData] = useState({ pageIndex: pageIndex, pageSize: pageSize, totalCount: 0, ext: {} });
    const [search, setSearch] = useState(params);

    const loadData = async () => {
        setLoading(true);
        var params = { pageIndex: pageExpandData.pageIndex, pageSize: pageExpandData.pageSize, ...search, ...sourceParams }
        var result = await request.GetData(url, params)
        if (result.Code === 1) {
            setPageData({ data: result.Result.data });
            setPageExpandData({ pageIndex: result.Result.pageIndex, pageSize: result.Result.pageSize, totalCount: result.Result.totalCount, ext: result.Result.ext });
            setLoading(false);
            return true;
        }
        else
            global.addMessage(result.Message);
        setLoading(false);
        return false;
    }

    const searchSubmit = async (values) => {
        setSearch(values)
        pageExpandData.pageIndex = 0;
        setPageExpandData({ ...pageExpandData });
        return true;
    }

    const handleChangePage = (event, newPageIndex) => {
        pageExpandData.pageIndex = newPageIndex;
        setPageExpandData({ ...pageExpandData });
    };

    useEffect(() => {
        loadData();
    }, [pageExpandData.pageIndex, search]);

    return children && children({
        pageData: {
            data: pageData.data,
            pageIndex: pageExpandData.pageIndex,
            pageSize: pageExpandData.pageSize,
            totalCount: pageExpandData.totalCount,
            ext: pageExpandData.ext
        },
        handleChangePage,
        searchSubmit,
        loading,
    });
}
export const Pager = forwardRef(_Pager);

//上传组件
export function UpLoadInput({ title = '图片', name = 'Pic', multiple = false, formik, width = 100, height = 100 }) {

    var error = ""; try { error = eval(`formik.errors.${name}`); } catch { }
    console.log(`formik.values.${name}`)
    var values = eval(`formik.values.${name}`);
    values = multiple ? values : [values];

    const theme = useTheme();
    const { getFieldProps, setFieldValue } = formik;

    const removeHanle = (index) => {
        var _pics = multiple ? [].concat(values) : [];
        _pics.splice(index, 1);
        setFieldValue(name, multiple ? _pics : _pics[0]);
    }
    return (
        <Stack sx={{ position: 'relative' }}>
            <Paper variant="outlined" sx={Boolean(error) ? { p: 2, borderColor: theme.palette.error.main } : { p: 2 }} >
                <Stack spacing={1.5} direction={multiple ? 'column' : 'row'} alignItems='center' >
                    <Stack direction={'row'} spacing={1} >
                        {
                            values.map((pic, picIndex) => {
                                if (multiple)
                                    return (
                                        <Stack style={{ position: 'relative' }}>
                                            <img src={pic} style={{ width: width, height: height }} />
                                            <Icon onClick={() => removeHanle(picIndex)} style={{ position: 'absolute', right: 0 }} icon="carbon:close-outline" />
                                        </Stack>
                                    )
                                else
                                    return <img src={pic} style={{ width: width, height: height }} />;
                            })
                        }
                    </Stack>
                    <TextField
                        autoComplete={name}
                        type="text"
                        label={title}
                        {...getFieldProps(name)}
                        //error={Boolean(touched.Pics && errors.Pics)}
                        //helperText={touched.Pics && errors.Pics}
                        sx={{ display: 'none' }}
                    />
                    <Stack direction={'row'} spacing={1.5}>
                        <label htmlFor={`contained-button-file-${name}`}>
                            <input style={{ display: 'none' }} accept="image/*" id={`contained-button-file-${name}`} type="file" multiple={multiple} onChange={async (e) => {
                                //console.log('e.target.files', e.target.files)
                                if (e.target.files.length > 0) {
                                    var _pics = multiple ? [].concat(values) : [];
                                    //console.log('_pics', _pics)
                                    for (var i = 0; i < e.target.files.length; i++) {
                                        //console.log('for', i)
                                        var result = await request.PostFile(e.target.files[i]);
                                        if (result.Code === 1) {
                                            console.log(result.Result.FilePath)
                                            _pics.push(result.Result.FilePath);
                                        }
                                    }
                                    console.log('_name', name)
                                    console.log('_pics', _pics)
                                    setFieldValue(name, multiple ? _pics : _pics[0]);
                                }
                            }} />
                            <Button variant="contained" component="span">
                                上传
                            </Button>
                        </label>
                        <Button color="grey" variant="contained" component="span" sx={{ mr: 2 }}
                            onClick={() => { setFieldValue(name, multiple ? [] : ''); }}
                        >
                            清空
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
            <Typography variant='caption' px={1} sx={{ position: 'absolute', top: '-10px', left: '10px', paddingLeft: '5px', paddingRight: '5px', display: 'block', backgroundColor: '#FFF' }}>{title}</Typography>
            {Boolean(error) && <Stack ml={2} mt={0.5}><Typography color='error' variant='caption'>{error}</Typography></Stack>}
        </Stack>
    );
}

export const getHelperAndErrorProps = (formik, name) => {
    const { errors, touched } = formik;
    var error = false;
    try { error = eval(`{touched.${name} && Boolean(errors.${name})}`); } catch { }
    var helperText = '';
    try { helperText = eval(`{touched.${name} && errors.${name}}`); } catch { }
    return { error, helperText };
}

//编辑器控件
export const RichText = ({ title = '内容', name = 'Text', formik, width = '100%' }) => {
    var value = eval(`formik.values.${name}`);
    const { getFieldProps, setFieldValue } = formik;
    return (
        <>
            <Typography variant='caption' px={2}>{title}</Typography>
            <Paper variant="outlined" sx={{ width: width }} style={{ marginTop: 4 }}>
                <ReactQuill key={name} value={value}
                    modules={{
                        toolbar: [
                            [{ 'header': [1, 2, 3, false] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            ['link', 'image'],
                            ['clean']
                        ],
                    }}
                    onChange={(content) => {
                        setFieldValue(name, content);
                    }}
                />
            </Paper>
        </>
    )
}