import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext, useParams } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function Header({ numSelected, submit, loading = false }) {
    const LoginSchema = Yup.object().shape({
        Key: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            Key: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            console.log('values', values);
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>

                            <Stack direction={'row'}>
                                <TextField
                                    type="text"
                                    label="关键词"
                                    {...getFieldProps('Key')}
                                />
                                <Stack flex={1}></Stack>
                            </Stack>

                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function Index() {
    const pager = useRef(null);
    const [setTools] = useOutletContext();
    const theme = useTheme();
    //console.log('theme', theme)
    let { id } = useParams();

    const TABLE_HEAD = [
        { label: '序号', id: 'Num' },
        { label: '评论人', id: 'DynamicMessageUser' },
        { label: '评论内容', id: 'DynamicMessageComment' },
        { label: '评论时间', id: 'UpTimeComment' },
        { label: '回复内容', id: 'DynamicMessageCommentReply' },
        { label: '回复时间', id: 'UpTimeReply' },
        { label: '操作', id: '', align: 'right' }
    ];

    useEffect(() => {
        setTools([

        ]);
    }, []);

    const [open, setOpen] = React.useState(false);
    const [huifuID, setHuifuID] = React.useState(0);
    const [huifuTxt, setHuifuTxt] = React.useState('');
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const submitHuiFu = async () => {
        if (huifuTxt.trim().length < 1)
            global.addMessage('内容不能为空');
        else {
            let url = '/Web/Office/DynamicMessage/DynamicMessageCommentReplySubmit';
            var result = await request.PostFormData(url, { id: huifuID, text: huifuTxt });

            if (result.Code === 1) {
                handleClose();
                pager.current?.refresh();
            }
            else { global.addMessage(result.Result); }
        }
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>回复评论</DialogTitle>
                <DialogContent sx={{ minWidth: 500, mt: 1.5 }}>
                    <TextField
                        autoFocus
                        label="回复内容"
                        type="text"
                        fullWidth
                        value={huifuTxt}
                        onChange={(e) => setHuifuTxt(e.target.value)}
                        multiline
                        rows={2}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={submitHuiFu}>确定</Button>
                </DialogActions>
            </Dialog>
            <Card>
                <Pager ref={pager} url='/Web/Office/DynamicMessage/DynamicMessageCommentList' sourceParams={{ DMID: id }} pageSize={50}>
                    {({ pageData, handleChangePage, searchSubmit, loading }) => {
                        return (
                            <>
                                <Header numSelected={0} submit={searchSubmit} loading={loading} />
                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {TABLE_HEAD.map((headCell) => (
                                                        <TableCell align={headCell.align ?? 'inherit'}>
                                                            <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {pageData.data.map((row, index) => {
                                                    const { id, CommentMessage, UpTime } = row.dmc;
                                                    const { id: dmcr_id, ReplyMessage: dmcr_ReplyMessage, UpTime: dmcr_UpTime } = row.dmcr;
                                                    const { Name } = row.o;
                                                    const { NickName } = row.ub;
                                                    return (
                                                        <TableRow hover>
                                                            <TableCell align="left">{index + 1}</TableCell>
                                                            <TableCell align="left">{Name && NickName}</TableCell>
                                                            <TableCell align="left">{CommentMessage}</TableCell>
                                                            <TableCell align="left">{UpTime}</TableCell>
                                                            <TableCell align="left">{dmcr_id > 0 ? dmcr_ReplyMessage : ''}</TableCell>
                                                            <TableCell align="left">{dmcr_id > 0 ? dmcr_UpTime : ''}</TableCell>
                                                            <TableCell align="right">
                                                                <MoreMenu Sub={
                                                                    [
                                                                        {
                                                                            label: dmcr_id > 0 ? '编辑回复' : '回复',
                                                                            icon: <Icon icon={editFill} width={24} height={24} />,
                                                                            onClick: () => {
                                                                                setHuifuID(id);
                                                                                setHuifuTxt(dmcr_id > 0 ? dmcr_ReplyMessage : '');
                                                                                handleClickOpen();
                                                                            }
                                                                        }
                                                                    ]
                                                                } />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <TablePagination
                                    rowsPerPageOptions={-1}
                                    component="div"
                                    count={pageData.totalCount}
                                    rowsPerPage={pageData.pageSize}
                                    page={pageData.pageIndex}
                                    onPageChange={handleChangePage}
                                />
                            </>
                        )
                    }}
                </Pager>
            </Card>
        </>
    );
}