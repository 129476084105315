import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useOutletContext, useParams } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Box,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    IconButton, TableHead,
    Menu, MenuItem, ListItemIcon, ListItemText, useTheme
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

export default function DetailAuthorization({ SetTools }) {
    const [setTools] = useOutletContext();
    let { id } = useParams();
    const theme = useTheme();
    const [data, setData] = useState({});

    const loadData = async () => {
        var result = await request.GetData('/Web/Admin/Set/AuthorizationDetail?id=' + id,{})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else
            global.addMessage(result.Message);
    }

    useEffect(() => {
        setTools([
            /* {
                title: '添加账号',
                icon: 'carbon:add',
                path: '/dashboard/set/account/create',
            } */
        ]);

        loadData();
    }, []);

    const ItemView = ({ title, value }) => {
        return (
            <Stack direction={'row'} alignItems={'center'} pb={3}>
                <Typography variant='body1' align='right' color={'textSecondary'} minWidth={250} pr={3}>{title}</Typography>
                <Typography variant='body1' color={'textPrimary'}>{value}</Typography>
            </Stack>
        )
    }

    return (
        <Card>
            {/* {selected.length > 0 &&
                <Header numSelected={selected.length} />
            } */}
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Box px={2} py={3}>
                        <ItemView title={'生效'} value={<Icon style={{ color: data.Lock ? theme.palette.grey[400] : theme.palette.primary.main }} icon={data.Lock ? "ep:close-bold" : "ep:select"} />} />
                        <ItemView title={'授权码'} value={data.Code} />
                        <ItemView title={'小区类型'} value={data.VillageType === 'notOpen' ? "封闭小区" : "开放小区"} />
                        <ItemView title={'子账号数'} value={data.SubAccountCount + "个"} />
                        <ItemView title={'车位订单抽取佣金比例(平台)'} value={(data.GetParkingCommissionScale * 100) + '%'} />
                        <ItemView title={'车位订单抽取佣金比例(物业)'} value={(data.GetParkingCommissionScale_wy * 100) + '%'} />
                        <ItemView title={'商城订单抽取佣金比例'} value={(data.GetShoppingCommissionScale * 100) + '%'} />
                        <ItemView title={'授权费用'} value={'￥' + data.TotalPrice} />
                        <ItemView title={'授权时间'} value={(data.StartTime && data.StartTime.Format('yy/M/d')) + ' - ' + (data.EndTime && data.EndTime.Format('yy/M/d'))} />
                        <ItemView title={'是否支付'} value={data.HasPay ? '已支付' : '未支付'} />
                        <ItemView title={'支付时间'} value={data.PayTime && data.PayTime.Format('yy/M/d h:m:s')} />
                        <ItemView title={'支付凭证'} value={<img style={{ maxWidth: 800, maxHeight: 500 }} src={data.PayAttachFile} />} />
                    </Box>
                </TableContainer>
            </Scrollbar>
        </Card>
    );
}
