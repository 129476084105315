import PropTypes from 'prop-types';
// material
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------


export default function Index({ sx }) {
  return (
    <Box p={1} sx={{
      color: '#000', opacity: 0.8
    }}>
      <Typography variant="caption" display="block" >Copyright 2022 成都星通星信息科技有限公司 All Rights Reserved</Typography>
    </Box >
  );
}
