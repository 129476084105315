import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { reactLocalStorage } from 'reactjs-localstorage';
import request from '../../request'

import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const isDevelopment = global.isDevelopment;
  const { companyAccount, companyName, computerSN } = props;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(isDevelopment);

  //尝试登录
  const test = async () => {
    let localStorage_Authorization = reactLocalStorage.get('Authorization', null);
    if (localStorage_Authorization != null && localStorage_Authorization.length > 0) {
      console.log('test login', localStorage_Authorization);
      const result = await request.PostFormData('Web/Office/Test/CheckPing', {});
      if (result.Code === 1) {
        navigate('/dashboard', { replace: true });
      }
      else {
        reactLocalStorage.remove('Authorization');
      }
    }
  }

  useEffect(() => {
    test();
  }, []);

  const LoginSchema = Yup.object().shape({
    computerSN: Yup.string().required('设备唯一编号 必填'),
    companyAccount: Yup.string().required('公司编号 必填'),
    account: Yup.string().required('账号（手机号或邮箱）必填'),
    password: Yup.string().required('密码必填')
  });

  const formik = useFormik({
    initialValues: {
      computerSN: computerSN,
      companyAccount: companyAccount,
      companyName: companyName,
      account: isDevelopment ? '17761214112' : '',
      password: isDevelopment ? '123456' : '',
      //remember: false
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      //验证
      console.log('values', values);

      submit(values).then(result => {
        if (!result) {
          actions.setSubmitting(false);
        }
      })
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const submit = async (values) => {
    const data = { Account: values.companyAccount, MobileOrEmail: values.account, Password: values.password, DeviceCode: computerSN }
    const result = await request.PostFormData('User/PropertyLogin', data);
    if (result.Code === 1) {
      reactLocalStorage.set('Authorization', result.Result.Token);
      navigate('/dashboard', { replace: true });
    }
    else
      global.addMessage(result.Message);
    return false;
  }

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <div style={{ display: global.isDevelopment ? 'block' : 'none' }}>
            <TextField
              disabled
              fullWidth
              autoComplete="computerSN"
              type="text"
              label="设备唯一编号"
              {...getFieldProps('computerSN')}
              error={Boolean(touched.computerSN && errors.computerSN)}
              helperText={touched.computerSN && errors.computerSN}
            />
          </div>

          <TextField
            disabled
            fullWidth
            autoComplete="companyAccount"
            type="text"
            label="公司编号"
            {...getFieldProps('companyAccount')}
            error={Boolean(touched.companyAccount && errors.companyAccount)}
            helperText={touched.companyAccount && errors.companyAccount}
          />

          <TextField
            fullWidth
            autoComplete="shareCarAccount"
            type="text"
            label="账号（手机号或邮箱）"
            {...getFieldProps('account')}
            error={Boolean(touched.account && errors.account)}
            helperText={touched.account && errors.account}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="密码"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}
        <Stack sx={{ my: 2 }}></Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          登录
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
