import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Typography,
    Card, CardHeader,
    Avatar,
    Button, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import { UpLoadInput } from '../MyComponents';
import request from '../../components/request'

const CSelect = (props) => {
    let { labelId, label, source } = props;

    let [data, setData] = useState([]);
    const loadData = async () => {
        var result = await request.GetData(source, {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else {

        }
    }

    useEffect(() => {
        loadData();
    }, [source])

    return (
        <FormControl sx={{ minWidth: 140 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                {...props}
            >
                {data.map((item) => <MenuItem value={item.id}>{item.Name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default function Index() {
    const [qRCodePath, SetQRCodePath] = useState(null);

    const loadData = async () => {
        var result = await request.PostFormData('Web/Public/GetLoginHFMsg')
        if (result.Code === 1) {
            formik.setValues(result.Result);
        }
        else {
            global.addMessage(result.Message);
        }
    }
    const loadData2 = async () => {
        var result = await request.GetData('Web/Office/Collect/GetQRCode')
        if (result.Code === 1) {
            SetQRCodePath(result.Result.Path);
        }
        else {
            global.addMessage(result.Message);
        }
    }

    useEffect(() => {
        loadData();
        loadData2();
    }, [])

    // const LoginSchema = Yup.object().shape({
    //     NickName: Yup.string().required('昵称 必填'),
    //     //HeadPortrait: Yup.string().required('头像 必填'),
    //     Name: Yup.string().required('真实姓名/公司名 必填'),
    //     IDCardNo: Yup.string().required('身份证号码/公司证件号码 必填'),
    //     ProvinceId: Yup.string().required('省份 必填'),
    //     CityId: Yup.string().required('城市 必填'),
    //     AreaId: Yup.string().required('区域 必填'),
    //     Address: Yup.string().required('地址 必填')
    // });

    // const formik = useFormik({
    //     initialValues: {
    //         NickName: '',
    //         HeadPortrait: '',
    //         Name: '',
    //         IDCardNo: '',
    //         ProvinceId: '',
    //         CityId: '',
    //         AreaId: '',
    //         Address: ''
    //     },
    //     validationSchema: LoginSchema,
    //     onSubmit: (values, actions) => {
    //         actions.setSubmitting(true);
    //         //验证
    //         console.log('values', JSON.stringify(values));
    //         submit(values).then(result => {
    //             //if (!result) {
    //             actions.setSubmitting(false);
    //             //}
    //         })
    //     }
    // });
    const formik = useFormik({
        initialValues: {
            RealName: '',
            IDCardNo: '',
            Mobile: '',
            BankCardNumber: '',
            status: '',
            HF_id: '',
        },
        onSubmit: () => { }
    });

    // const submit = async (values) => {
    //     const data = values;
    //     const result = await request.PostJsonData('Web/Public/UpdateLoginProfile', data);
    //     if (result.Code === 1) {
    //         global.addMessage(result.Message);
    //         return true;
    //     }
    //     else
    //         global.addMessage(result.Message);

    //     return false;
    // }

    const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;
    const data = values;
    const Input = styled('input')({
        display: 'none',
    });

    //console.log('values', values)

    return (
        <Card>
            <Stack direction={'row'} spacing={3} >
                <Stack flex={1}>
                    <CardHeader title="收款码" />
                    <Box p={3}>
                        {qRCodePath &&
                            <Stack alignItems={'center'}>
                                <div>
                                    <img src={qRCodePath} width='360' />
                                </div>
                                <div style={{ marginTop: 10 }}>微信收款码</div>
                            </Stack>
                        }
                    </Box>
                </Stack>
                <Stack flex={1}>
                    <CardHeader title="实名&收款 信息" />
                    <Box p={3}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Stack spacing={3} alignItems={'stretch'}>
                                    {/* <Stack direction={'column'} spacing={2}>
                                        <TextField
                                            autoComplete="Name"
                                            type="text"
                                            fullWidth
                                            label="真实名称"
                                            disabled
                                            {...getFieldProps('Name')}
                                            error={Boolean(touched.Name && errors.Name)}
                                            helperText={touched.Name && errors.Name}
                                        />
                                        <TextField
                                            autoComplete="IDCardNo"
                                            type="text"
                                            fullWidth
                                            disabled
                                            label="证件号码"
                                            {...getFieldProps('IDCardNo')}
                                            error={Boolean(touched.IDCardNo && errors.IDCardNo)}
                                            helperText={touched.IDCardNo && errors.IDCardNo}
                                        />
                                    </Stack>
                                    <Stack direction={'row'} spacing={2}>
                                        <CSelect
                                            source={"Web/Public/Province"}
                                            labelId="ProvinceId"
                                            autoComplete="ProvinceId"
                                            type="text"
                                            label="省份"
                                            disabled
                                            {...getFieldProps('ProvinceId')}
                                            error={Boolean(touched.ProvinceId && errors.ProvinceId)}
                                            helperText={touched.ProvinceId && errors.ProvinceId}
                                            onChange={(e) => {
                                                formik.setFieldValue('CityId', '');
                                                formik.setFieldValue('AreaId', '');
                                                handleChange(e);
                                            }}
                                        />
                                        <CSelect
                                            source={"Web/Public/City?ProvinceId=" + values.ProvinceId}
                                            labelId="CityId"
                                            autoComplete="CityId"
                                            type="text"
                                            label="城市"
                                            disabled
                                            {...getFieldProps('CityId')}
                                            error={Boolean(touched.CityId && errors.CityId)}
                                            helperText={touched.CityId && errors.CityId}
                                            onChange={(e) => {
                                                formik.setFieldValue('CityId', '');
                                                handleChange(e);
                                            }}
                                        />
                                        <CSelect
                                            source={"Web/Public/Area?ProvinceId=" + values.ProvinceId + "&CityId=" + values.CityId}
                                            labelId="AreaId"
                                            autoComplete="AreaId"
                                            type="text"
                                            label="区域"
                                            disabled
                                            {...getFieldProps('AreaId')}
                                            error={Boolean(touched.AreaId && errors.AreaId)}
                                            helperText={touched.AreaId && errors.AreaId}
                                        />
                                    </Stack>
                                    <TextField
                                        fullWidth
                                        autoComplete="Address"
                                        type="text"
                                        label="详细地址"
                                        disabled
                                        {...getFieldProps('Address')}
                                        error={Boolean(touched.Address && errors.Address)}
                                        helperText={touched.Address && errors.Address}

                                    /> */}
                                    <Stack direction={'column'} spacing={2}>
                                        <TextField
                                            autoComplete="RealName"
                                            type="text"
                                            fullWidth
                                            label="真实名称"
                                            disabled
                                            {...getFieldProps('RealName')}
                                        // error={Boolean(touched.RealName && errors.RealName)}
                                        // helperText={touched.RealName && errors.RealName}
                                        />
                                        <TextField
                                            autoComplete="IDCardNo"
                                            type="text"
                                            fullWidth
                                            disabled
                                            label="证件号码"
                                            {...getFieldProps('IDCardNo')}
                                        // error={Boolean(touched.IDCardNo && errors.IDCardNo)}
                                        // helperText={touched.IDCardNo && errors.IDCardNo}
                                        />
                                        <TextField
                                            autoComplete="Mobile"
                                            type="text"
                                            fullWidth
                                            disabled
                                            label="手机号码"
                                            {...getFieldProps('Mobile')}
                                        // error={Boolean(touched.IDCardNo && errors.IDCardNo)}
                                        // helperText={touched.IDCardNo && errors.IDCardNo}
                                        />
                                        <TextField
                                            autoComplete="BankCardNumber"
                                            type="text"
                                            fullWidth
                                            disabled
                                            label="银行卡"
                                            {...getFieldProps('BankCardNumber')}
                                        // error={Boolean(touched.IDCardNo && errors.IDCardNo)}
                                        // helperText={touched.IDCardNo && errors.IDCardNo}
                                        />
                                        <TextField
                                            autoComplete="status"
                                            type="text"
                                            fullWidth
                                            disabled
                                            label="状态"
                                            {...getFieldProps('status')}
                                        />
                                        <TextField
                                            autoComplete="HF_id"
                                            type="text"
                                            fullWidth
                                            disabled
                                            label="Huifu_ID"
                                            {...getFieldProps('HF_id')}
                                        // error={Boolean(touched.IDCardNo && errors.IDCardNo)}
                                        // helperText={touched.IDCardNo && errors.IDCardNo}
                                        />
                                    </Stack>
                                </Stack>

                                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}
                                {/* <Stack sx={{ my: 4 }}></Stack>

                                <LoadingButton

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    更新
                                </LoadingButton> */}
                            </Form>
                        </FormikProvider>
                    </Box>
                </Stack>
            </Stack>
        </Card >
    );
}
