import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Typography,
    Paper,
    Card, CardHeader,
    Avatar,
    Button, FormControl, Select, InputLabel, MenuItem, Switch,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput, getHelperAndErrorProps, RichText } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cnLocale from 'date-fns/locale/zh-CN';


export default function Detail() {
    const theme = useTheme();
    //console.log('theme', theme);
    const navigete = useNavigate();
    const [setTools] = useOutletContext();
    let { id } = useParams();
    const [data, setData] = useState(null);

    const loadData = async () => {
        var result = await request.GetData('Web/Office/VisitorOrder/VisitorOrderOne?id=' + id, {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else {
            global.addMessage(result.Message);
        }
    }
    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (data != null)
            setTools([
                {
                    title: '查看记录',
                    icon: 'carbon:add',
                    path: `/dashboard/visitororder/inoutlog/${data.order.VisitorOrderNumber}`,
                }
            ])
    }, [data])


    if (data == null) return null;

    let { VisitorOrderNumber, VisitorOrderType, Pics, Remark, StartTime, EndTime, UpTime, PeopleCount, NickName, Name, Mobile, JiDong, DanYuan, FangHao } = data.order;

    return (
        <Card>
            <Box p={3}>
                <Stack direction={'row'} pb={1}>
                    <Item flex={1} title={'预约编号'} content={VisitorOrderNumber} />
                    <Item flex={1} title={'预约类型'} content={VisitorOrderType} />
                    <Item flex={1} title={'更新时间'} content={UpTime} />
                </Stack>
                <Stack direction={'row'} pb={1}>
                    <Item flex={1} title={'人数'} content={PeopleCount} />
                    <Item flex={1} title={'开始时间'} content={StartTime} />
                    <Item flex={1} title={'结束时间'} content={EndTime} />
                </Stack>
                <Stack direction={'row'} pb={1}>
                    <Item flex={1} title={'业主'} content={(Name && NickName) + Mobile} />
                    <Item flex={1} title={'地址'} content={JiDong + DanYuan + FangHao} />
                    <Item flex={1} title={''} content={''} />
                </Stack>
                <Stack direction={'row'} pb={1}>
                    <Item flex={1} title={'备注'} content={Remark} />
                    <Item flex={1} title={''} content={''} />
                    <Item flex={1} title={''} content={''} />
                </Stack>
                <Stack direction={'row'} pb={1}>
                    <Item flex={1} title={'照片'} content={
                        <Stack direction={'row'} pr={1}>
                            {
                                Pics && Pics.map((pic) =>
                                    <Box mr={0.5} mb={0.5}><a target={'_blank'} href={pic}><img width={120} src={pic} /></a></Box>)
                            }
                        </Stack>
                    } />
                </Stack>
                <Stack direction={'column'} pt={3} pb={1}>
                    <Stack pr={2}><Typography variant='subtitle1'>{'人员信息'}</Typography></Stack>
                    <Stack direction={'row'} spacing={1}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>序号</TableCell>
                                        <TableCell>姓名</TableCell>
                                        <TableCell>性别</TableCell>
                                        <TableCell>手机号</TableCell>
                                        <TableCell>证件类型</TableCell>
                                        <TableCell>证件号码</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.items.map((item, itemIndex) => {
                                            let { id: itemId, VisitorOrderId, VisitorName, VisitorSex, VisitorMobile, VisitorIdentityType, VisitorIdentityNumber } = item;
                                            return (
                                                <TableRow
                                                    key={itemId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>{itemIndex + 1}</TableCell>
                                                    <TableCell>{VisitorName}</TableCell>
                                                    <TableCell>{VisitorSex}</TableCell>
                                                    <TableCell>{VisitorMobile}</TableCell>
                                                    <TableCell>{VisitorIdentityType}</TableCell>
                                                    <TableCell>{VisitorIdentityNumber}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* {BicyclePics.map(item => <a target={'_blank'} href={item}><img width='80' src={item} /></a>)} */}
                    </Stack>
                </Stack>
            </Box>
        </Card >
    );
}

const Item = (props) => {
    let { title, content } = props;
    return (
        <Stack direction={'row'} {...props}>
            <Stack pr={2}><Typography variant='subtitle2'>{title}</Typography></Stack>
            <Stack><Typography variant='body2'>{content}</Typography></Stack>
        </Stack>
    )
}