import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Box,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'

import { getHelperAndErrorProps } from '../MyComponents';

import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cnLocale from 'date-fns/locale/zh-CN';

const CSelect = (props) => {
    let { labelId, label, source } = props;

    let [data, setData] = useState([]);
    const loadData = async () => {
        var result = await request.GetData(source, {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else {

        }
    }

    useEffect(() => {
        loadData();
    }, [source])

    return (
        <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                {...props}
            >
                <MenuItem value={''}>--全部--</MenuItem>
                {data.map((item) => <MenuItem value={item.name}>{item.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

function Header({ numSelected, submit, loading = false }) {

    const LoginSchema = Yup.object().shape({
        StartTime: Yup.date().nullable(),
        EndTime: Yup.date().nullable(),
        Key: Yup.string().nullable()
    });

    const formik = useFormik({
        initialValues: {
            StartTime: null,
            EndTime: null,
            Key: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            values = { t: Math.random(), ...values };
            console.log('values', values);
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Stack direction={'row'} spacing={2}>
                                <CSelect
                                    labelId='ParkingSpaceType'
                                    label='类型'
                                    source='/Public/GetEnumValsByGet?EnumName=models.Community.ParkingSpaceType'
                                    {...getFieldProps('ParkingSpaceType')}
                                />
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={cnLocale}>
                                    <DateRangePicker
                                        startText="开始时间"
                                        endText="结束时间"
                                        value={[values.StartTime, values.EndTime]}
                                        onChange={(newValue) => {
                                            //console.log(newValue);
                                            try {
                                                setFieldValue('StartTime', newValue[0].Format('yyyy-MM-dd'));
                                                setFieldValue('EndTime', newValue[1].Format('yyyy-MM-dd'));
                                            } catch (e) { }
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <TextField {...startProps}
                                                    {...getFieldProps('StartTime')}
                                                    {...getHelperAndErrorProps(formik, 'StartTime')}
                                                    style={{ width: 120 }}
                                                />
                                                <Box sx={{ mx: 2 }}> 到 </Box>
                                                <TextField {...endProps}
                                                    {...getFieldProps('EndTime')}
                                                    {...getHelperAndErrorProps(formik, 'EndTime')}
                                                    style={{ width: 120 }}
                                                />
                                            </React.Fragment>
                                        )}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    type="text"
                                    label="车位号"
                                    {...getFieldProps('ParkingSpaceNumber')}
                                />
                                <TextField
                                    type="text"
                                    label="关键词"
                                    {...getFieldProps('Key')}
                                />
                                <Stack flex={1}></Stack>
                            </Stack>

                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function Index() {
    const [setTools] = useOutletContext();
    const theme = useTheme(); console.log('theme', theme)

    const TABLE_HEAD = [
        { label: '时间', id: 'AtTime', },
        { label: '金额', id: 'Amount', align: 'right' },
        { label: '标题/备注', id: 'Title', align: 'center' },
        { label: '结算状态', id: 'Settlement' },
        { label: '结算备注', id: 'SettlementRemark' },
    ];

    useEffect(() => {
        setTools([
        ]);
    }, []);

    return (
        <Card>
            <Pager url='/Web/Office/Bill/List' sourceParams={{ Type: '车位' }}>
                {({ pageData, handleChangePage, searchSubmit, loading }) => {
                    return (
                        <>
                            <Header numSelected={0} submit={searchSubmit} loading={loading} />
                            <Stack>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack ml={2} sx={{ color: '#212B36', fontWeight: 'bold' }}>合计：¥{pageData.ext.totalAmount}</Stack>
                                    {/* <Button onClick={handleSubmit}  >
                                        导出数据
                                    </Button> */}
                                </Stack>
                            </Stack>
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                    <TableCell align={headCell.align ?? 'inherit'}>
                                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pageData.data.map((row, index) => {
                                                const { id, Amount, Title, Remark, AtTime,
                                                    NeedSettlement, IsSettlement, SettlementTime, SettlementNumber } = row;
                                                return (
                                                    <TableRow hover>
                                                        <TableCell align="left">
                                                            {AtTime}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {Amount}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {Title}<br />
                                                            <span style={{ color: '#ababab' }}>{Remark}</span>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {NeedSettlement ? (!IsSettlement ? "未结算" : "已结算") : '--'}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {IsSettlement &&
                                                                <>
                                                                    <div>{SettlementNumber}</div>
                                                                    <div>{SettlementTime}</div>
                                                                </>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>
                            <Stack>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack ml={2} sx={{ color: '#212B36', fontWeight: 'bold' }}>合计：¥{pageData.ext.totalAmount}</Stack>
                                    <TablePagination
                                        rowsPerPageOptions={-1}
                                        component="div"
                                        count={pageData.totalCount}
                                        rowsPerPage={pageData.pageSize}
                                        page={pageData.pageIndex}
                                        onPageChange={handleChangePage}
                                    />
                                </Stack>
                            </Stack>
                        </>
                    )
                }}
            </Pager>
        </Card >
    );
}
