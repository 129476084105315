import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    IconButton, TableHead,
    Menu, MenuItem, ListItemIcon, ListItemText
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { MoreMenu } from '../MyComponents'


function Header({ numSelected }) {
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter'
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : null}

            {numSelected > 0 ? (
                <IconButton>
                    <Icon icon={'fluent:delete-24-filled'} />
                </IconButton>
            ) : null}
        </Stack>
    );
}


export default function Account() {
    const [setTools] = useOutletContext();
    const [data, setData] = useState([]);

    const TABLE_HEAD = [
        { label: '头像', id: 'HeadPortrait' },
        { label: '昵称', id: 'NickName' },
        { label: '角色', id: 'Claim' },
        { label: '手机', id: 'Mobile' },
        { label: '邮箱', id: 'Email' },
        { label: '姓名', id: 'Name' },
        { label: '操作', id: '', align: 'right' }
    ];

    const loadData = async () => {
        var result = await request.GetData('/Web/Admin/Set/Account', {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else
            global.addMessage(result.Message);
    }

    useEffect(() => {
        setTools([
            {
                title: '添加账号',
                icon: 'carbon:add',
                path: '/dashboard/set/account/create',
            }
        ]);

        loadData();
    }, []);

    const getClaim = (Claim) => {
        switch (Claim) {
            case "office": return "办公室";
            case "guard": return "门卫";
        }
        return Claim;
    }

    return (
        <Card>
            {/* {selected.length > 0 &&
                <Header numSelected={selected.length} />
            } */}
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {TABLE_HEAD.map((headCell) => (
                                    <TableCell align={headCell.align ?? 'inherit'}>
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.map((row) => {
                                const { id, NickName, Mobile, Email, HeadPortrait, Name, Claim } = row;
                                return (
                                    <TableRow hover>
                                        <TableCell align="left"><Avatar src={HeadPortrait} /></TableCell>
                                        <TableCell align="left">{NickName}</TableCell>
                                        <TableCell align="left">{getClaim(Claim)}</TableCell>
                                        <TableCell align="left">{Mobile}</TableCell>
                                        <TableCell align="left">{Email}</TableCell>
                                        <TableCell align="left">{Name}</TableCell>
                                        <TableCell align="right">
                                            <MoreMenu Sub={
                                                [
                                                    {
                                                        label: '编辑',
                                                        icon: <Icon icon={editFill} width={24} height={24} />,
                                                        to: `/dashboard/set/account/edit/${id}`
                                                    }
                                                ]
                                            } />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
    );
}
