import { useState, useEffect, useHistory } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import { Icon } from '@iconify/react';
import peopleFill from '@iconify/icons-eva/people-fill';

import { reactLocalStorage } from 'reactjs-localstorage';
import request from '../../components/request'
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------
export async function GetUserInfo() {
  let localStorage_Authorization = reactLocalStorage.get('Authorization', null);
  if (localStorage_Authorization != null && localStorage_Authorization.length > 0) {
    console.log('localStorage_Authorization', localStorage_Authorization);
    let result = await request.PostFormData('Web/Office/Test/CheckPing', {});
    if (result.Code === 1) {
      let strings = localStorage_Authorization.split("."); //截取token，获取载体
      var userInfo = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/"))))); //解析，需要吧‘_’,'-'进行转换否则会无法解析
      console.log('userInfo', userInfo);
      return userInfo;
    }
    else {
      //navigate('/', { replace: true });
      global.myHistory.replace("/");
    }
  }
  else {
    //navigate('/', { replace: true });
    global.myHistory.replace("/");
  }
}

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}