import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'

export default function OwnerImport() {
    const [setTools] = useOutletContext();
    const theme = useTheme(); console.log('theme', theme)

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        setTools([
        ]);
    }, []);

    const Input = styled('input')({
        display: 'none',
    });

    return (
        <Card>
            <Stack p={2} direction={'row'} justifyContent={'space-between'} alignItems={'self-start'}>
                <label htmlFor="contained-button-file">
                    <Input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="contained-button-file" multiple type="file" onChange={async (e) => {
                        if (e.target.files.length > 0) {
                            setLoading(true)
                            var result = await request.PostFile(e.target.files[0]);
                            if (result.Code === 1) {
                                let filePath = result.Result.FilePath;
                                let url = '/Web/Office/Owner/ImportData';
                                var result = await request.PostFormData(url, { excelUrl: filePath });
                                if (result.Code === 1) {
                                    setResult(result.Result);
                                }
                                else {
                                    global.addMessage(result.Message);
                                }
                            }
                        }
                    }} />
                    <Button variant="contained" component="span">
                        开始导入业主数据
                    </Button>
                </label>

                <Button onClick={async () => {
                    await request.DownloadFile('/Web/Office/Owner/DownloadExample');
                }} color="secondary">下载数据模版</Button>
            </Stack>
            <Stack p={2}>
                {
                    result &&
                    <>
                        <Typography variant='body2'>总共导入数据：{result.exist + result.fail + result.success} 条</Typography>
                        <Typography variant='body2'>成功导入数据：{result.success} 条</Typography>
                        <Typography variant='body2'>失败导入数据：{result.fail} 条</Typography>
                        <Typography variant='body2'>已存在的数据：{result.exist} 条</Typography>
                    </>
                }
            </Stack>
        </Card>
    );
}
