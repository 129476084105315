import React from 'react';
import { Outlet, matchPath, useLocation, } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Link,
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../../components/Page';
import { SubMenus, TopRightTools } from '../MyComponents'

export default function ProfileLayout() {
  
  const [tools, setTools] = useState([]);

  const subMenus = [
    {
      title: '资料',
      icon: 'icomoon-free:profile',
      path: '/dashboard/profile/app'
    },
    {
      title: '安全',
      icon: 'ps:bank-safe',
      path: '/dashboard/profile/safe'
    }
  ]

  return (
    <Page title="Profile | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display={'flex'}>
          <SubMenus Menus={subMenus} />
          </Box>

          <TopRightTools Tools={tools} />

        </Stack>

        <Outlet context={[setTools]} />

      </Container>
    </Page>
  );
}
