import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import { UpLoadInput } from '../MyComponents';
import request from '../../components/request'

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.Name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function Index() {

  const loadData = async () => {
    var result = await request.GetData('Web/Admin/Set/PaymentOne')
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    loadData();
  }, [])

  const LoginSchema = Yup.object().shape({
    PaymentType: Yup.string().required('支付方式 必填'),
    Mch_id: Yup.string().required('商户号 必填'),
  });

  const formik = useFormik({
    initialValues: {
      PaymentType: '',
      Mch_id: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = await request.PostJsonData('Web/Admin/Set/UpdatePayment', data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

  const Input = styled('input')({
    display: 'none',
  });

  //console.log('values', values)

  return (
    <Card>
      {/* <CardHeader title="基础信息" subheader="" /> */}
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <TextField
                autoComplete="PaymentType"
                type="text"
                label="支付方式"
                disabled={true}
                {...getFieldProps('PaymentType')}
                error={Boolean(touched.PaymentType && errors.PaymentType)}
                helperText={touched.PaymentType && errors.PaymentType}
              />
              <TextField
                autoComplete="Mch_id"
                type="text"
                label="商户号码"
                {...getFieldProps('Mch_id')}
                error={Boolean(touched.Mch_id && errors.Mch_id)}
                helperText={touched.Mch_id && errors.Mch_id}
              />
              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton

                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                保存
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
