import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    IconButton, TableHead,
    useTheme
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu } from '../MyComponents'


function Header({ numSelected }) {
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter'
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : null}

            {numSelected > 0 ? (
                <IconButton>
                    <Icon icon={'fluent:delete-24-filled'} />
                </IconButton>
            ) : null}
        </Stack>
    );
}

export default function Authorization() {
    const [setTools] = useOutletContext();
    const theme = useTheme();
    console.log('theme', theme)
    const [data, setData] = useState([]);

    const TABLE_HEAD = [
        { label: '小区类型', id: 'VillageType' },
        { label: '子账号', id: 'SubAccountCount' },
        { label: '车位佣金(平台)', id: 'GetParkingCommissionScale' },
        { label: '车位佣金(物业)', id: 'GetParkingCommissionScale_wy' },
        { label: '商城佣金', id: 'GetShoppingCommissionScale' },
        { label: '软件授权费', id: 'TotalPrice' },
        { label: '授权时间', id: 'AuthorizationDate' },
        { label: '生效', id: 'Lock' },
        { label: '操作', id: '', align: 'right' }
    ];

    const loadData = async () => {
        var result = await request.GetData('/Web/Admin/Set/Authorization', {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else
            global.addMessage(result.Message);
    }

    useEffect(() => {
        setTools([
            /* {
                title: '添加账号',
                icon: 'carbon:add',
                path: '/dashboard/set/account/create',
            } */
        ]);

        loadData();
    }, []);

    return (
        <Card>
            {/* {selected.length > 0 &&
                <Header numSelected={selected.length} />
            } */}
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {TABLE_HEAD.map((headCell) => (
                                    <TableCell align={headCell.align ?? 'inherit'}>
                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.map((row) => {
                                const { id, VillageType, SubAccountCount, GetParkingCommissionScale, GetParkingCommissionScale_wy, GetShoppingCommissionScale, TotalPrice, HasPay, StartTime, EndTime, Lock } = row;
                                return (
                                    <TableRow hover>
                                        <TableCell align="left">{VillageType === 'notOpen' ? "封闭小区" : "开放小区"}</TableCell>
                                        <TableCell align="left">{SubAccountCount + '个'}</TableCell>
                                        <TableCell align="left">{(GetParkingCommissionScale * 100) + '%'}</TableCell>
                                        <TableCell align="left">{(GetParkingCommissionScale_wy * 100) + '%'}</TableCell>
                                        <TableCell align="left">{(GetShoppingCommissionScale * 100) + '%'}</TableCell>
                                        <TableCell align="left">{'￥' + TotalPrice}</TableCell>
                                        <TableCell align="left">{StartTime.Format('yy/M/d') + ' - ' + EndTime.Format('yy/M/d')}</TableCell>
                                        <TableCell align="left"><Icon style={{ color: Lock ? theme.palette.grey[400] : theme.palette.primary.main }} icon={Lock ? "ep:close-bold" : "ep:select"} /></TableCell>
                                        <TableCell align="right">
                                            <MoreMenu Sub={
                                                [
                                                    {
                                                        label: '查看',
                                                        icon: <Icon icon={editFill} width={24} height={24} />,
                                                        to: `/dashboard/set/authorization/detail/${id}`
                                                    },
                                                ]
                                            } />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
    );
}
