import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'


function Header({ numSelected, submit, loading = false }) {

    const LoginSchema = Yup.object().shape({
        Key: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            Key: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            console.log('values', values);
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>

                            <Stack direction={'row'}>
                                <TextField
                                    type="text"
                                    label="类型/编号/姓名/联系方式/位置/备注/车牌"
                                    {...getFieldProps('Key')}
                                    sx={{ minWidth: 400 }}
                                />
                                <Stack flex={1}></Stack>
                            </Stack>

                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function Index() {
    const [setTools] = useOutletContext();
    const theme = useTheme(); console.log('theme', theme)

    const TABLE_HEAD = [
        { label: '序号', id: 'Num' },
        { label: '编号/类型', id: 'Type' },
        // { label: '车位编号', id: 'Number' },
        { label: '姓名/手机', id: 'Name' },
        // { label: '手机', id: 'Mobile' },
        { label: '位置/子库编号', id: 'Position' },
        // { label: '子库编号', id: 'SubWareCode' },
        { label: '备注', id: 'Remark' },
        { label: '认证', id: 'Authorization' },
        { label: '状态', id: 'ScanStatus' },
        { label: '预约', id: 'IsOrder' },
        { label: '车牌/扫码进出', id: 'CanScan' },
        { label: '操作', id: '', align: 'right' }
    ];

    useEffect(() => {
        setTools([
            {
                title: '导入车位数据',
                icon: 'carbon:add',
                path: '/dashboard/parkingspace/list/import',
            },
            {
                title: '重置所有车位状态',
                // icon: 'carbon:delete',
                onClick: async () => {
                    if (window.confirm('确定要重置所有车位状态吗？')) {
                        const result = await request.PostJsonData('Web/Office/ParkingSpace/ReStatusAllParkingSpace', {});
                        if (result.Code === 1) {
                            global.addMessage(result.Message);
                            global.myHistory.back();
                            return true;
                        }
                        else
                            global.addMessage(result.Message);
                        return false;
                    }
                },
            }
        ]);
    }, []);

    return (
        <Card>
            <Pager url='/Web/Office/ParkingSpace/List' pageSize={50}>
                {({ pageData, handleChangePage, searchSubmit, loading }) => {
                    return (
                        <>
                            <Header numSelected={0} submit={searchSubmit} loading={loading} />
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                    <TableCell align={headCell.align ?? 'inherit'}>
                                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pageData.data.map((row, index) => {
                                                const { id, Type, Number, OwnerName, OwnerMobile, Position, SubWareCode, Remark, CanScan, ScanEndTime, LicensePlates, ScanStatus, LastCurrentOrderNumber, CanCharge, CanCharge_Kuai } = row.ps;
                                                let isOrder = (LastCurrentOrderNumber ?? "").length > 0;
                                                let Authorization = row.psb.id > 0;
                                                let ScanStatusTxt = "--";
                                                const { Mobile: WXMobile, HeadPortrait, NickName } = row.ub;
                                                switch (ScanStatus) {
                                                    // case 0: break;
                                                    case 1: ScanStatusTxt = '在库'; break;
                                                    case 2: ScanStatusTxt = '出库'; break;
                                                }
                                                return (
                                                    <TableRow hover>
                                                        <TableCell align="left">{index + 1}</TableCell>
                                                        <TableCell align="left">{Number}<br />{Type}</TableCell>
                                                        {/* <TableCell align="left">{Number}</TableCell> */}
                                                        <TableCell align="left">{OwnerName}<br />{OwnerMobile}</TableCell>
                                                        {/* <TableCell align="left">{OwnerMobile}</TableCell> */}
                                                        <TableCell align="left">
                                                            {Position}<br />
                                                            {SubWareCode}
                                                        </TableCell>
                                                        {/* <TableCell align="left">{SubWareCode}</TableCell> */}
                                                        <TableCell align="left">
                                                            <Stack direction={'row'} alignItems={'center'}>
                                                                {Remark}
                                                                {CanCharge == true && <><svg t="1728782477029" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="32120" width="26" height="26"><path d="M870.4 311.296v463.872c0 80.384-66.56 80.384-77.312 80.384-7.68 0-78.848-1.024-78.848-81.408V458.24s-1.024-12.288-14.336-12.288h-36.352v413.696h29.184c11.776 0 22.016 9.216 22.016 20.48v30.208c0 11.264-9.728 21.504-22.016 21.504H204.8c-11.776 0-22.016-9.728-22.016-21.504v-30.208c0-11.264 9.728-20.48 22.016-20.48h30.72v-670.72c0-27.648 23.552-50.176 53.248-50.176h322.56c29.184 0 53.248 22.016 53.248 50.176v209.408c18.944 0 54.272-0.512 62.976 0 35.84 1.536 39.424 14.848 39.424 44.544v327.68c0 22.016 13.824 29.184 27.136 29.184 13.312 0 26.112-6.656 26.112-29.184V369.664s-1.024-4.608-5.632-4.608H747.52c-1.536 0-5.12-1.024-5.12-4.096 0-3.072-0.512-8.192-0.512-16.384 0-1.024 0-3.072 1.536-4.608 13.312-13.312 34.304-28.672 46.592-43.52-15.36-15.36-41.984-36.352-53.76-47.104-8.192-7.68-13.312-22.016-2.048-33.28 11.776-11.264 29.696-7.168 37.376 0 13.312 12.8 58.88 50.176 84.992 72.704 14.336 11.264 13.824 22.528 13.824 22.528zM356.352 454.144h176.64c20.48 0 36.864-15.872 36.864-34.816V233.984c0-18.944-16.896-34.816-36.864-34.816H356.352c-20.48 0-36.864 15.872-36.864 34.816v184.832c0 19.456 16.384 35.328 36.864 35.328z m166.4 54.272l-220.16 211.968h133.632L373.76 869.888l210.944-214.528h-122.88l60.928-146.944z" p-id="32121" fill="#00ab55"></path></svg></>
                                                                }
                                                                {CanCharge_Kuai == true && <><svg t="1728782477029" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="32120" width="26" height="26"><path d="M870.4 311.296v463.872c0 80.384-66.56 80.384-77.312 80.384-7.68 0-78.848-1.024-78.848-81.408V458.24s-1.024-12.288-14.336-12.288h-36.352v413.696h29.184c11.776 0 22.016 9.216 22.016 20.48v30.208c0 11.264-9.728 21.504-22.016 21.504H204.8c-11.776 0-22.016-9.728-22.016-21.504v-30.208c0-11.264 9.728-20.48 22.016-20.48h30.72v-670.72c0-27.648 23.552-50.176 53.248-50.176h322.56c29.184 0 53.248 22.016 53.248 50.176v209.408c18.944 0 54.272-0.512 62.976 0 35.84 1.536 39.424 14.848 39.424 44.544v327.68c0 22.016 13.824 29.184 27.136 29.184 13.312 0 26.112-6.656 26.112-29.184V369.664s-1.024-4.608-5.632-4.608H747.52c-1.536 0-5.12-1.024-5.12-4.096 0-3.072-0.512-8.192-0.512-16.384 0-1.024 0-3.072 1.536-4.608 13.312-13.312 34.304-28.672 46.592-43.52-15.36-15.36-41.984-36.352-53.76-47.104-8.192-7.68-13.312-22.016-2.048-33.28 11.776-11.264 29.696-7.168 37.376 0 13.312 12.8 58.88 50.176 84.992 72.704 14.336 11.264 13.824 22.528 13.824 22.528zM356.352 454.144h176.64c20.48 0 36.864-15.872 36.864-34.816V233.984c0-18.944-16.896-34.816-36.864-34.816H356.352c-20.48 0-36.864 15.872-36.864 34.816v184.832c0 19.456 16.384 35.328 36.864 35.328z m166.4 54.272l-220.16 211.968h133.632L373.76 869.888l210.944-214.528h-122.88l60.928-146.944z" p-id="32121" fill="#087c41"></path></svg></>
                                                                }
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Stack className='Authorization'
                                                                sx={{
                                                                    position: 'relative',
                                                                    "& .view1": { display: 'none' },
                                                                    "&:hover .view1": { display: 'block' }
                                                                }}
                                                            >
                                                                <Icon style={{ color: !Authorization ? theme.palette.grey[400] : theme.palette.primary.main }} icon={!Authorization ? "ep:close-bold" : "ep:select"} />
                                                                {Authorization &&
                                                                    <Stack className='view1' spacing={1}
                                                                        sx={{
                                                                            borderRadius: 1,
                                                                            position: 'absolute',
                                                                            width: 200,
                                                                            backgroundColor: '#FFF',
                                                                            zIndex: 999,
                                                                            p: 2,
                                                                            boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 20px 40px -4px rgb(145 158 171 / 24%)',
                                                                            border: 'solid 1px rgba(145, 158, 171, 0.08)'
                                                                        }}>
                                                                        {HeadPortrait && <div><img width={40} src={HeadPortrait} /></div>}
                                                                        <div>{NickName}({WXMobile})</div>
                                                                    </Stack>
                                                                }
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="left">{ScanStatusTxt}</TableCell>
                                                        <TableCell align="left">
                                                            <Stack
                                                                sx={{
                                                                    position: 'relative',
                                                                    "& .view1": { display: 'none' },
                                                                    "&:hover .view1": { display: 'block' }
                                                                }}
                                                            >
                                                                <Icon style={{ color: !isOrder ? theme.palette.grey[400] : theme.palette.primary.main }} icon={!isOrder ? "ep:close-bold" : "ep:select"} />
                                                                {isOrder &&
                                                                    <Stack className='view1' spacing={1}
                                                                        sx={{
                                                                            borderRadius: 1,
                                                                            position: 'absolute',
                                                                            width: 200,
                                                                            backgroundColor: '#FFF',
                                                                            zIndex: 999,
                                                                            p: 2,
                                                                            boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 20px 40px -4px rgb(145 158 171 / 24%)',
                                                                            border: 'solid 1px rgba(145, 158, 171, 0.08)'
                                                                        }}>
                                                                        <div>订单:{LastCurrentOrderNumber}</div>
                                                                    </Stack>
                                                                }
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Stack direction={'row'} alignItems={'center'}>
                                                                <Icon style={{ color: !CanScan ? theme.palette.grey[400] : theme.palette.primary.main }} icon={!CanScan ? "ep:close-bold" : "ep:select"} />
                                                                <span style={{ marginLeft: 3 }}>{ScanEndTime && ScanEndTime.Format()}</span>
                                                            </Stack>
                                                            <Stack>
                                                                {LicensePlates}
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MoreMenu Sub={
                                                                [
                                                                    {
                                                                        label: '编辑',
                                                                        icon: <Icon icon={editFill} width={24} height={24} />,
                                                                        to: `/dashboard/parkingspace/list/edit/${id}`
                                                                    },
                                                                ]
                                                            } />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={-1}
                                component="div"
                                count={pageData.totalCount}
                                rowsPerPage={pageData.pageSize}
                                page={pageData.pageIndex}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )
                }}
            </Pager>
        </Card>
    );
}
