import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  Switch,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import cnLocale from 'date-fns/locale/zh-CN';
import { UpLoadInput, getHelperAndErrorProps, RichText } from '../MyComponents';

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function EditParkingSpace() {
  const navigete = useNavigate();
  const [setTools] = useOutletContext();
  let { id } = useParams();

  const loadData = async () => {
    var result = await request.GetData('Web/Office/ParkingSpace/GetParkingSpace?id=' + id, {})
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    setTools([
      {
        title: '删除',
        icon: 'carbon:delete',
        onClick: async () => {
          if (window.confirm('确定要删除吗？')) {
            const result = await request.PostJsonData('Web/Office/ParkingSpace/DeleteParkingSpace?id=' + id, {});
            if (result.Code === 1) {
              global.addMessage(result.Message);
              global.myHistory.back();
              return true;
            }
            else
              global.addMessage(result.Message);
            return false;
          }
        },
      },
      {
        title: '解除认证信息',
        // icon: 'carbon:delete',
        onClick: async () => {
          if (window.confirm('确定要解除认证信息吗？')) {
            const result = await request.PostJsonData('Web/Office/ParkingSpace/JieBangParkingSpace?id=' + id, {});
            if (result.Code === 1) {
              global.addMessage(result.Message);
              global.myHistory.back();
              return true;
            }
            else
              global.addMessage(result.Message);
            return false;
          }
        },
      },
      {
        title: '重置车位状态',
        // icon: 'carbon:delete',
        onClick: async () => {
          if (window.confirm('确定要重置车位状态吗？')) {
            const result = await request.PostJsonData('Web/Office/ParkingSpace/ReStatusParkingSpace?id=' + id, {});
            if (result.Code === 1) {
              global.addMessage(result.Message);
              global.myHistory.back();
              return true;
            }
            else
              global.addMessage(result.Message);
            return false;
          }
        },
      }
    ]);
    loadData();
  }, [])

  const LoginSchema = Yup.object().shape({
    Type: Yup.string().required('类型 必填'),
    Number: Yup.string().required('编号 必填'),
    OwnerName: Yup.string().required('姓名 必填'),
    OwnerMobile: Yup.string().required('联系方式 必填'),
    Position: Yup.string(),//.required('位置 必填'),
    SubWareCode: Yup.string().nullable(),
    Remark: Yup.string(),//.required('备注 必填'),
    CanScan: Yup.boolean(),
    ScanEndTime: Yup.date().nullable(),
    LicensePlates: Yup.string().nullable(),
    CanCharge: Yup.boolean(),
    CanCharge_Kuai: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      Type: '',
      Number: '',
      OwnerName: '',
      OwnerMobile: '',
      Position: '',
      SubWareCode: '',
      Remark: '',
      CanScan: false,
      ScanEndTime: Yup.date().nullable(),
      LicensePlates: '',
      CanCharge: false,
      CanCharge_Kuai: false,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = await request.PostJsonData('Web/Office/ParkingSpace/UpdateParkingSpace?id=' + id, data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      //navigete('/dashboard/ParkingSpace/list', { replace: true });
      global.myHistory.back();
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps, setFieldValue } = formik;

  const Input = styled('input')({
    display: 'none',
  });

  //console.log('values', values)

  return (
    <Card>
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <CSelect
                source={"Public/GetEnumValsByGet?EnumName=models.Community.ParkingSpaceType&OnlyText=true"}
                labelId="Type"
                autoComplete="Type"
                type="text"
                label="类型"
                {...getFieldProps('Type')}
                error={Boolean(touched.Type && errors.Type)}
                helperText={touched.Type && errors.Type}
              />
              <TextField
                autoComplete="Number"
                type="text"
                label="编号"
                {...getFieldProps('Number')}
                error={Boolean(touched.Number && errors.Number)}
                helperText={touched.Number && errors.Number}
              />
              <TextField
                autoComplete="OwnerName"
                type="text"
                label="姓名"
                {...getFieldProps('OwnerName')}
                error={Boolean(touched.OwnerName && errors.OwnerName)}
                helperText={touched.OwnerName && errors.OwnerName}
              />
              <TextField
                autoComplete="OwnerMobile"
                type="text"
                label="联系方式"
                {...getFieldProps('OwnerMobile')}
                error={Boolean(touched.OwnerMobile && errors.OwnerMobile)}
                helperText={touched.OwnerMobile && errors.OwnerMobile}
              />
              <TextField
                autoComplete="Position"
                type="text"
                label="位置"
                {...getFieldProps('Position')}
                error={Boolean(touched.Position && errors.Position)}
                helperText={touched.Position && errors.Position}
              />
              <TextField
                autoComplete="SubWareCode"
                type="text"
                label="子库编号"
                {...getFieldProps('SubWareCode')}
                error={Boolean(touched.SubWareCode && errors.SubWareCode)}
                helperText={touched.SubWareCode && errors.SubWareCode}
              />
              <TextField
                autoComplete="Remark"
                type="text"
                label="备注"
                {...getFieldProps('Remark')}
                error={Boolean(touched.Remark && errors.Remark)}
                helperText={touched.Remark && errors.Remark}
              />
              <Stack direction={'row'} spacing={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name='CanCharge'
                      color="primary"
                      checked={values.CanCharge}
                      onChange={handleChange}
                    />
                  }
                  label="带充电桩(慢)"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Switch
                      name='CanCharge_Kuai'
                      color="primary"
                      checked={values.CanCharge_Kuai}
                      onChange={handleChange}
                    />
                  }
                  label="带充电桩(快)"
                  labelPlacement="end"
                />
              </Stack>
              <Stack p={2} sx={{ border: '1px solid #ddd', borderRadius: '8px' }} spacing={2}>
                <FormControlLabel
                  control={
                    <Switch
                      name='CanScan'
                      color="primary"
                      checked={values.CanScan}
                      onChange={handleChange}
                    />
                  }
                  label="扫码进出"
                  labelPlacement="end"
                />
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={cnLocale}>
                  <DatePicker
                    label="扫码允许最大时间"
                    ampm={false}
                    value={values.ScanEndTime}
                    onChange={(newValue) => {
                      try {
                        //console.log('newValue', newValue);
                        setFieldValue('ScanEndTime', newValue.Format('yyyy-MM-dd hh:mm:ss'));
                      } catch (e) { }
                    }}
                    renderInput={(params) => <TextField
                      {...params}
                      {...getFieldProps('ScanEndTime')}
                      {...getHelperAndErrorProps(formik, 'ScanEndTime')}
                    />}
                  />
                </LocalizationProvider>
              </Stack>

              <TextField
                sx={{ minWidth: 260 }}
                type="text"
                label="绑定车牌"
                {...getFieldProps('LicensePlates')}
                error={Boolean(touched.LicensePlates && errors.LicensePlates)}
                helperText={touched.LicensePlates && (errors.LicensePlates ?? `多个车牌用,号隔开`)}
              />

              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton

                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                保存
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
