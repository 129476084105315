import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Typography,
    Paper,
    Card, CardHeader,
    Avatar,
    Button, FormControl, Select, InputLabel, MenuItem, Switch,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput, getHelperAndErrorProps, RichText } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cnLocale from 'date-fns/locale/zh-CN';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const CSelect = (props) => {
    let { labelId, label, source } = props;

    let [data, setData] = useState([]);
    const loadData = async () => {
        var result = await request.GetData(source, {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else {

        }
    }

    useEffect(() => {
        loadData();
    }, [source])

    return (
        <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                {...props}
            >
                {data.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}


export default function Add() {
    const theme = useTheme();
    //console.log('theme', theme);
    const navigete = useNavigate();
    const [setTools] = useOutletContext();
    let { id } = useParams();
    let add = id === undefined;

    const loadData = async () => {
        var result = await request.GetData('Web/Office/Device/DeviceOne?id=' + id, {})
        if (result.Code === 1) {
            formik.setValues(result.Result);
        }
        else {
            global.addMessage(result.Message);
        }
    }

    const [pwd, setPwd] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setPwd('');
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSmt = async () => {
        const result = await request.PostFormData('Web/Office/Device/SetPwd?id=' + id, { pwd: pwd });
        if (result.Code === 1) {
            global.addMessage(result.Message);
        }
        else {
            global.addMessage(result.Message);
        }
        handleClose();
    }

    useEffect(() => {
        if (!add) {
            setTools([
                {
                    title: '删除',
                    icon: 'carbon:delete',
                    onClick: async () => {
                        if (window.confirm('确定要删除吗？')) {
                            const result = await request.PostJsonData('Web/Office/Device/DeleteDevice?id=' + id, {});
                            if (result.Code === 1) {
                                global.addMessage(result.Message);
                                global.myHistory.back();
                                return true;
                            }
                            else
                                global.addMessage(result.Message);
                            return false;
                        }
                    },
                },
                {
                    title: '设置密码',
                    onClick: async () => {
                        setPwd('');
                        setOpen(true);
                    },
                },
                {
                    title: '开门',
                    onClick: async () => {
                        if (window.confirm('确定要开门吗？')) {
                            const result = await request.PostJsonData('Web/Office/Device/Open?id=' + id, {});
                            if (result.Code === 1) {
                                global.addMessage(result.Message);
                                global.myHistory.back();
                                return true;
                            }
                            else
                                global.addMessage(result.Message);
                            return false;
                        }
                    },
                }
            ]);
            loadData();
        }
    }, [])

    const LoginSchema = Yup.object().shape({
        DeviceType: Yup.string().required('设备类型 必填').nullable(),
        SN: Yup.string().required('设备编号 必填'),
        Name: Yup.string().required('设备名称 必填'),

        Open: Yup.boolean(),

        IsCheWei: Yup.boolean(),
        CheWeiInOrOut: Yup.string().nullable(),
        SubWareCode: Yup.string().nullable(),

        IsDanYuan: Yup.boolean(),
        Dong: Yup.string().nullable(),
        DanYuan: Yup.string().nullable(),

        IsElse: Yup.boolean(),
        ElseCode: Yup.string().nullable(),
        ElseRemark: Yup.string().nullable(),

        RelevanceDeviceSN: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            DeviceType: '',
            SN: '',
            Name: '',
            Open: true,
            IsCheWei: false,
            CheWeiInOrOut: '',
            SubWareCode: '',
            IsDanYuan: false,
            Dong: '',
            DanYuan: '',
            IsElse: false,
            ElseCode: '',
            ElseRemark: '',
            RelevanceDeviceSN: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            //验证
            console.log('values', JSON.stringify(values));
            submit(values).then(result => {
                //if (!result) {
                actions.setSubmitting(false);
                //}
            })
        }
    });

    const submit = async (values) => {
        const data = values;
        const result = add ?
            await request.PostJsonData('Web/Office/Device/CreateDevice', data) :
            await request.PostJsonData('Web/Office/Device/UpdateDevice?id=' + id, data);
        if (result.Code === 1) {
            global.addMessage(result.Message);
            //navigete('/dashboard/ParkingSpace/list', { replace: true });
            global.myHistory.back();
            return true;
        }
        else
            global.addMessage(result.Message);

        return false;
    }

    const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps, setFieldValue } = formik;
    //console.log('formik', formik)
    //console.log('touched', touched)

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>设置开门密码</DialogTitle>
                <DialogContent sx={{ width: 350 }}>
                    <DialogContentText>
                        请设置6位数字密码
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="密码"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={pwd}
                        onChange={e => setPwd(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={handleSmt}>保存</Button>
                </DialogActions>
            </Dialog>

            <Card>
                <Box p={3}>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3} alignItems={'flex-start'}>

                                <FormControlLabel
                                    control={
                                        <Switch
                                            name='Open'
                                            color="primary"
                                            checked={values.Open}
                                            onChange={handleChange}
                                        />
                                    }
                                    label="开启"
                                    labelPlacement="end"

                                />

                                <Stack direction="row" spacing={2}>
                                    <CSelect
                                        source={"Public/GetEnumValsByGet?EnumName=models.Community.DeviceType&OnlyText=true"}
                                        labelId="DeviceType"
                                        autoComplete="DeviceType"
                                        type="text"
                                        label="设备类型"
                                        {...getFieldProps('DeviceType')}
                                        error={Boolean(touched.DeviceType && errors.DeviceType)}
                                        helperText={touched.DeviceType && errors.DeviceType}
                                    />
                                    <TextField
                                        autoComplete="SN"
                                        type="text"
                                        label="设备编号"
                                        {...getFieldProps('SN')}
                                        {...getHelperAndErrorProps(formik, 'SN')}
                                        sx={{}}
                                    />
                                    <TextField
                                        autoComplete="Name"
                                        type="text"
                                        label="设备名称"
                                        {...getFieldProps('Name')}
                                        {...getHelperAndErrorProps(formik, 'Name')}
                                        sx={{}}
                                    />
                                </Stack>

                                <Stack direction="row" spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='IsCheWei'
                                                color="primary"
                                                checked={values.IsCheWei}
                                                onChange={handleChange}
                                            />
                                        }
                                        label="车位设备"
                                        labelPlacement="end"

                                    />
                                    <Stack direction={'row'} alignItems={'center'} spacing={2} >
                                        <InputLabel id="demo-simple-select-label">车位进/出</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            {...getFieldProps('CheWeiInOrOut')}
                                            {...getHelperAndErrorProps(formik, 'CheWeiInOrOut')}
                                        >
                                            <MenuItem value={'in'}>进口</MenuItem>
                                            <MenuItem value={'out'}>出口</MenuItem>
                                        </Select>
                                    </Stack>
                                    <TextField
                                        autoComplete="SubWareCode"
                                        type="text"
                                        label="子库编号"
                                        {...getFieldProps('SubWareCode')}
                                        {...getHelperAndErrorProps(formik, 'SubWareCode')}
                                        sx={{}}
                                    />
                                </Stack>

                                <Stack direction="row" spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='IsDanYuan'
                                                color="primary"
                                                checked={values.IsDanYuan}
                                                onChange={handleChange}
                                            />
                                        }
                                        label="单元设备"
                                        labelPlacement="end"

                                    />

                                    <TextField
                                        autoComplete="Dong"
                                        type="text"
                                        label="栋数"
                                        {...getFieldProps('Dong')}
                                        {...getHelperAndErrorProps(formik, 'Dong')}
                                        sx={{}}
                                    />

                                    <TextField
                                        autoComplete="DanYuan"
                                        type="text"
                                        label="单元"
                                        {...getFieldProps('DanYuan')}
                                        {...getHelperAndErrorProps(formik, 'DanYuan')}
                                        sx={{}}
                                    />
                                </Stack>

                                <Stack direction="row" spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='IsElse'
                                                color="primary"
                                                checked={values.IsElse}
                                                onChange={handleChange}
                                            />
                                        }
                                        label="其它设备"
                                        labelPlacement="end"

                                    />

                                    <TextField
                                        autoComplete="ElseCode"
                                        type="text"
                                        label="编号"
                                        {...getFieldProps('ElseCode')}
                                        {...getHelperAndErrorProps(formik, 'ElseCode')}
                                        sx={{}}
                                    />

                                    <TextField
                                        autoComplete="ElseRemark"
                                        type="text"
                                        label="备注"
                                        {...getFieldProps('ElseRemark')}
                                        {...getHelperAndErrorProps(formik, 'ElseRemark')}
                                        sx={{}}
                                    />
                                </Stack>

                                <Stack direction="row" spacing={2}>

                                    <TextField
                                        autoComplete="RelevanceDeviceSN"
                                        type="text"
                                        label="关联设备"
                                        {...getFieldProps('RelevanceDeviceSN')}
                                        {...getHelperAndErrorProps(formik, 'RelevanceDeviceSN')}
                                        sx={{}}
                                        helperText="填写相同位置车牌摄像机的SN编码"
                                    />

                                </Stack>

                                <Stack sx={{ my: 4 }}></Stack>

                                <LoadingButton

                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    保存
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </Box>
            </Card>
        </>
    );
}
