import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useOutletContext, useParams } from 'react-router-dom';
// material
import {
    Card,
    CardHeader,
    Table,
    Stack,
    Box,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    IconButton, TableHead,
    Menu, MenuItem, ListItemIcon, ListItemText, useTheme
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

export default function Index({ SetTools }) {
    const [setTools] = useOutletContext();
    let { id } = useParams();
    const theme = useTheme();
    const [data, setData] = useState({});

    const loadData = async () => {
        var result = await request.GetData('/Web/Office/ProductOrder/PropertyOrderDetail?id=' + id, {})
        if (result.Code === 1) {
            let _data = result.Result;
            setData(_data);
            let { Status } = _data;
            let tools = [];
            if (Status == '已下单') {
                tools.push({
                    title: '取消订单',
                    icon: 'carbon:add',
                    onClick: async () => {
                        if (window.confirm('确定要取消该订单')) {
                            const result = await request.GetData('Web/Office/ProductOrder/CancelOrder?id=' + id, {});
                            if (result.Code === 1) {
                                global.addMessage(result.Message);
                                global.myHistory.back();
                                return true;
                            }
                            else
                                global.addMessage(result.Message);
                            return false;
                        }
                    },
                });
            }
            if (Status == '已支付') {
                tools.push({
                    title: '确认完成',
                    icon: 'carbon:add',
                    onClick: async () => {
                        if (window.confirm('确定已完成该订单')) {
                            const result = await request.GetData('Web/Office/ProductOrder/FinishOrder?id=' + id, {});
                            if (result.Code === 1) {
                                global.addMessage(result.Message);
                                global.myHistory.back();
                                return true;
                            }
                            else
                                global.addMessage(result.Message);
                            return false;
                        }
                    },
                });
            }
            setTools(tools);
        }
        else
            global.addMessage(result.Message);
    }

    useEffect(() => {
        loadData();
    }, []);

    const ItemView = ({ title, value }) => {
        return (
            <Stack direction={'row'} alignItems={'center'} pb={3}>
                <Typography variant='body1' align='right' color={'textSecondary'} minWidth={220} pr={3}>{title}</Typography>
                <Typography variant='body1' color={'textPrimary'}>{value}</Typography>
            </Stack>
        )
    }

    return (
        <Card>
            <Scrollbar>
                <CardHeader title="基础信息" subheader="" />
                <Stack px={4} pt={1} direction={'row'}>
                    <Typography flex={1} variant='body2'>订单号：{data.OrderNumber}</Typography>
                    <Typography flex={1} variant='body2'>下单时间：{data.CreateTime}</Typography>
                    <Typography flex={1} variant='body2'>订单状态：{data.Status}</Typography>
                </Stack>
                {data.IsFinish &&
                    <Stack px={4} pt={1} direction={'row'}>
                        <Typography flex={1} variant='body2'>完成时间：{data.FinishTime}</Typography>
                        <Typography flex={1} variant='body2'>完成备注：{data.FinishRemark}</Typography>
                        <Typography flex={1} variant='body2'></Typography>
                    </Stack>
                }
                {data.IsClose &&
                    <Stack px={4} pt={1} direction={'row'}>
                        <Typography flex={1} variant='body2'>关闭时间：{data.CloseTime}</Typography>
                        <Typography flex={1} variant='body2'>关闭备注：{data.CloseRemark}</Typography>
                        <Typography flex={1} variant='body2'></Typography>
                    </Stack>
                }
                <CardHeader title="产品信息" subheader="" />
                <Stack px={4} pt={1} direction={'row'} >
                    <Table flex={1} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>图片</TableCell>
                                <TableCell>产品/服务信息</TableCell>
                                <TableCell align="">规格</TableCell>
                                <TableCell align="">单价</TableCell>
                                <TableCell align="">数量</TableCell>
                                <TableCell align="right">总价</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row"><img width={46} src={data.PSPic} /></TableCell>
                                <TableCell >{data.PTitle}</TableCell>
                                <TableCell align="">{`[${data.PSCode}]${data.PSName}`}</TableCell>
                                <TableCell align="">
                                    <Typography variant='body2'>{`${data.Price}元`}</Typography>
                                    {data.TotalIntegral > 0 && <Typography variant='body2'>{`${data.Integral}分`}</Typography>}
                                </TableCell>
                                <TableCell align="">{data.Count}</TableCell>
                                <TableCell align="right">
                                    <Typography variant='body2'>{`${data.TotalPrice}元`}</Typography>
                                    {data.TotalIntegral > 0 && <Typography variant='body2'>{`${data.TotalIntegral}分`}</Typography>}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
                <CardHeader title="支付信息" subheader="" />
                <Stack px={4} pt={1} direction={'row'}  >
                    <Typography flex={1} variant='body2'>订单支付：{data.Price_IsPay ? '已支付' : '未支付'}</Typography>
                    <Typography flex={1} variant='body2'>支付时间：{data.Price_PayTime}</Typography>
                    <Typography flex={1} variant='body2'>支付备注：{data.Price_PayRemark}</Typography>
                </Stack>
                <Stack px={4} pt={1} direction={'row'}  >
                    <Typography flex={1} variant='body2'>订单支付（积分）：{data.Integral_IsPay ? '已支付' : '未支付'}</Typography>
                    <Typography flex={1} variant='body2'>支付时间：{data.Integral_PayTime}</Typography>
                    <Typography flex={1} variant='body2'>支付备注：{data.Integral_PayRemark}</Typography>
                </Stack>
                <CardHeader title="会员信息" subheader="" />
                <Stack px={4} pt={1} direction={'row'}  >
                    <Typography flex={1} variant='body2'>省份：{data.AddresseeSheng}</Typography>
                    <Typography flex={1} variant='body2'>城市：{data.AddresseeShi}</Typography>
                    <Typography flex={1} variant='body2'>区县：{data.AddresseeQu}</Typography>
                </Stack>
                <Stack px={4} pt={1} pb={4} direction={'row'}  >
                    <Typography flex={1} variant='body2'>订购人：{data.AddresseeName}</Typography>
                    <Typography flex={1} variant='body2'>联系方式：{data.AddresseeMobile}</Typography>
                    <Typography flex={1} variant='body2'>门牌地址：{data.AddresseeAddress}</Typography>
                </Stack>
            </Scrollbar>
        </Card>
    );
}
