import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    IconButton, TableHead,
    useTheme
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu } from '../MyComponents'


function Header({ numSelected }) {
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter'
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : null}

            {numSelected > 0 ? (
                <IconButton>
                    <Icon icon={'fluent:delete-24-filled'} />
                </IconButton>
            ) : null}
        </Stack>
    );
}

export default function ShangHu() {
    const [setTools] = useOutletContext();
    const theme = useTheme();
    // console.log('theme', theme)
    const [data, setData] = useState([]);

    const TABLE_HEAD = [
        { label: '头像', id: 'Avatar' },
        { label: '商户账号', id: 'Account' },
        { label: '商户昵称', id: 'NickName' },
        // { label: '密码', id: 'Pwd' },
        { label: '最后登录', id: 'LastLoginTime' },
        { label: '创建登录', id: 'AtTime' },
    ];

    const loadData = async () => {
        var result = await request.GetData('/Web/Admin/Set/Shanghu', {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else
            global.addMessage(result.Message);
    }

    useEffect(() => {
        setTools([
            {
                title: '添加',
                icon: 'carbon:add',
                path: '/dashboard/set/shanghu/create',
            }
        ]);

        loadData();
    }, []);

    return (
        <Card>
            {/* {selected.length > 0 &&
                <Header numSelected={selected.length} />
            } */}
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {TABLE_HEAD.map((headCell) => (
                                    <TableCell align={headCell.align ?? 'inherit'}>
                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.map((row) => {
                                const { id, Avatar, Account, NickName, LastLoginTime, AtTime } = row;
                                return (
                                    <TableRow hover>
                                        <TableCell align="left">
                                            <img src={Avatar} style={{ width: 60, height: 60 }} />
                                        </TableCell>
                                        <TableCell align="left">{Account}</TableCell>
                                        <TableCell align="left">{NickName}</TableCell>
                                        <TableCell align="left">{LastLoginTime}</TableCell>
                                        <TableCell align="left">{AtTime}</TableCell>
                                        <TableCell align="right">
                                            <MoreMenu Sub={
                                                [
                                                    {
                                                        label: '查看',
                                                        icon: <Icon icon={editFill} width={24} height={24} />,
                                                        to: `/dashboard/set/shanghu/create/${id}`
                                                    },
                                                ]
                                            } />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
    );
}
