import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={`${item.id}`}>{item.Name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function CreateDoor() {
  const navigete = useNavigate();
  const [setTools] = useOutletContext();
  let { id } = useParams();

  const loadData = async () => {
    var result = await request.GetData('Web/Admin/Set/GetDoor?id=' + id, {})
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    setTools([]);
    if (id) loadData();
  }, [])

  const LoginSchema = Yup.object().shape({
    Name: Yup.string().required('名称 必填'),
    Longitude: Yup.number().nullable().required('经度 必填'),
    Latitude: Yup.number().nullable().required('纬度 必填'),
  });

  const formik = useFormik({
    initialValues: {
      Name: '',
      Longitude: null,
      Latitude: null,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    let data = { ...values };
    if (!isNaN(parseInt(id))) data = { ...values, id: parseInt(id) };
    const result = await request.PostJsonData('Web/Admin/Set/CreateDoor', data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      navigete('/dashboard/set/door', { replace: true });
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

  const Input = styled('input')({
    display: 'none',
  });

  //console.log('values', values)

  return (
    <Card>

      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

            <Stack spacing={3} alignItems={'flex-start'}>

              <TextField
                autoComplete="Name"
                type="text"
                label="名称"
                {...getFieldProps('Name')}
                error={Boolean(errors.Name)}
                helperText={errors.Name}
              />
              <TextField
                autoComplete="Longitude"
                type="number"
                label="经度"
                {...getFieldProps('Longitude')}
                error={Boolean(errors.Longitude)}
                helperText={errors.Longitude}
              />
              <TextField
                autoComplete="Latitude"
                type="number"
                label="纬度"
                {...getFieldProps('Latitude')}
                error={Boolean(errors.Latitude)}
                helperText={errors.Latitude}
              />

            </Stack>

            <Stack sx={{ my: 4 }}></Stack>

            <LoadingButton

              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              保存
            </LoadingButton>

          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
