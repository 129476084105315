import { reactLocalStorage } from 'reactjs-localstorage';
import axios from 'axios';
import qs from 'qs'
import FormData from 'form-data';

//https://segmentfault.com/a/1190000015261229

class request {

    instance = (config) => {
        const _instance = axios.create({
            baseURL: global.apiUrl,
            timeout: 30 * 1000
        });
        if (config.addAuthorization)
            _instance.defaults.headers.common['Authorization'] = reactLocalStorage.get('Authorization', 'No Authorization');

        return _instance;
    }

    PostJsonData = (url, dataObj, config = { addAuthorization: true, autoRedirect: true }) => {
        return new Promise((resolve, reject) => {
            var req = this.instance(config).post(url, dataObj);
            this._dealResponse(req, resolve, reject, { url, autoRedirect: config.autoRedirect });
        });
    }


    PostFormData = (url, dataObj, config = { addAuthorization: true, autoRedirect: true }) => {
        return new Promise((resolve, reject) => {
            var req = this.instance(config).post(url, qs.stringify(dataObj));
            this._dealResponse(req, resolve, reject, { url, autoRedirect: config.autoRedirect });
        });
    }
    //上传单文件
    PostFile = (file, config = { addAuthorization: true, autoRedirect: true }) => {
        return new Promise((resolve, reject) => {
            const url = 'File/UpLoadFile';
            const form = new FormData();
            form.append('file', file);
            var req = this.instance(config).post(url, form);
            this._dealResponse(req, resolve, reject, { url, autoRedirect: config.autoRedirect });
        });
    }
    DownloadFile = (url, config = { addAuthorization: true, autoRedirect: true }) => {
        return new Promise((resolve, reject) => {
            let _ins = this.instance(config);
            _ins.post(url, {}, { responseType: 'blob' })
                .then((res) => {
                    console.log('res', res) 
                    const { data, headers } = res
                    const contentTypeArr = headers['content-type'].split(';');
                    const fileName = contentTypeArr[1].replace('filename=', '').trim();
                    const contentType = contentTypeArr[0].trim();
                    console.log('fileName', fileName, 'contentType', contentType)
                    // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
                    //const blob = new Blob([JSON.stringify(data)], ...)
                    const blob = new Blob([data], { type: headers['content-type'] })
                    let dom = document.createElement('a')
                    let url = window.URL.createObjectURL(blob)
                    dom.href = url
                    dom.download = decodeURI(fileName)
                    dom.style.display = 'none'
                    document.body.appendChild(dom)
                    dom.click()
                    dom.parentNode.removeChild(dom)
                    window.URL.revokeObjectURL(url)
                }).catch((err) => { })
            resolve(true);
        });
    }

    GetData = (url, params = {}, config = { addAuthorization: true, autoRedirect: true }) => {
        return new Promise((resolve, reject) => {
            var paramStr = qs.stringify(params);
            var _url = url + (paramStr.length > 0 ? ('?' + paramStr) : '');
            var req = this.instance(config).get(_url);
            this._dealResponse(req, resolve, reject, { url, autoRedirect: config.autoRedirect });
        });
    }

    _dealResponse = (req, resolve, reject, config) => {
        //console.log('global.navigate', global.navigate)
        let { url, autoRedirect } = config;
        const dealResponseStatus = this._dealResponseStatus;
        req.then((response) => {
            //console.log('response', response);
            if (response.status === 200)
                resolve(response.data);
            else {
                dealResponseStatus(response, resolve, reject, autoRedirect);
                resolve({ Code: -1, Message: 'Status Code: ' + response.status, Result: response });
            }
        }).catch(function (error) {
            console.log('request', error);
            if (error.response)
                dealResponseStatus(error.response, resolve, reject, autoRedirect);
            resolve({ Code: -1, Message: 'Request ' + url + ' return Error', Result: error });
        });
    }

    _dealResponseStatus = (response, resolve, reject, autoRedirect) => {
        let { status } = response
        if (status === 401) {
            if (autoRedirect) {
                setTimeout(() => {
                    try {
                        reactLocalStorage.remove('Authorization');
                        console.log("Remove authorization And Navigate to '/'");
                        console.log("**************logout**************");
                        global.navigate && global.navigate('/', { replace: true });
                    } catch (e) { }
                }, 200);
            }
            const log = "Login verification failed, Maybe expired";
            console.log('_dealResponseStatus', log);
            resolve({ Code: -1, Message: log, Result: response });
        }
        else if (response.status === 403) {
            const log = "No permission";
            console.log('_dealResponseStatus', log);
            resolve({ Code: -1, Message: log, Result: response });
        }
    }

}
export default new request();

