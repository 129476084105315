import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme,
    Box
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'

import { getHelperAndErrorProps } from '../MyComponents';

import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cnLocale from 'date-fns/locale/zh-CN';

function Header({ numSelected, submit, loading = false }) {

    const LoginSchema = Yup.object().shape({
        StartTime: Yup.date().nullable(),
        EndTime: Yup.date().nullable(),
        Key: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            StartTime: null,
            EndTime: null,
            Key: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            console.log('values', values);
            values = { ...values, t: Math.random() }
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Stack direction={'row'} spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={cnLocale}>
                                    <DateRangePicker
                                        startText="开始时间"
                                        endText="结束时间"
                                        value={[values.StartTime, values.EndTime]}
                                        onChange={(newValue) => {
                                            //console.log(newValue);
                                            try {
                                                setFieldValue('StartTime', newValue[0].Format('yyyy-MM-dd'));
                                                setFieldValue('EndTime', newValue[1].Format('yyyy-MM-dd'));
                                            } catch (e) { }
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <TextField {...startProps}
                                                    {...getFieldProps('StartTime')}
                                                    {...getHelperAndErrorProps(formik, 'StartTime')}
                                                    style={{ width: 120 }}
                                                />
                                                <Box sx={{ mx: 2 }}> 到 </Box>
                                                <TextField {...endProps}
                                                    {...getFieldProps('EndTime')}
                                                    {...getHelperAndErrorProps(formik, 'EndTime')}
                                                    style={{ width: 120 }}
                                                />
                                            </React.Fragment>
                                        )}
                                    />
                                </LocalizationProvider>

                                <Stack direction={'row'}>
                                    <TextField
                                        type="text"
                                        label="设备编号/关键词"
                                        {...getFieldProps('Key')}
                                    />
                                    <Stack flex={1}></Stack>
                                </Stack>
                            </Stack>
                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function Index() {
    const [setTools] = useOutletContext();
    const theme = useTheme(); console.log('theme', theme)

    const TABLE_HEAD = [
        { label: '序号', id: 'Num' },
        { label: '编号/时间', id: 'SN' },
        // { label: '日志标题/日志内容', id: 'Title' },
        { label: '日志标题/日志内容', id: 'Content' },
        { label: '附件', id: 'FuJian' },
        { label: '', id: 'UpTime', align: 'right' }
    ];

    useEffect(() => {
        setTools([
            // {
            //     title: '增加',
            //     icon: 'carbon:add',
            //     path: '/dashboard/Device/list/add',
            // }
        ]);
    }, []);

    return (
        <Card>
            <Pager url='/Web/Office/DeviceLog/DeviceLogList' pageSize={50}>
                {({ pageData, handleChangePage, searchSubmit, loading }) => {
                    const pageC = pageData.pageIndex * pageData.pageSize;
                    return (
                        <>
                            <Header numSelected={0} submit={searchSubmit} loading={loading} />
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                    <TableCell align={headCell.align ?? 'inherit'}>
                                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        {loading ?
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ textAlign: 'center' }} colSpan={5}>加载中...</TableCell>
                                                </TableRow>
                                            </TableBody> :
                                            <TableBody>
                                                {pageData.data.map((row, index) => {
                                                    let { id, SN, Title, Content, UpTime, PicData = '', PicFullData = '', ElseJson = '' } = row;
                                                    PicData = PicData ?? '';
                                                    PicFullData = PicFullData ?? '';
                                                    ElseJson = ElseJson ?? '';
                                                    let imgPic0 = PicData.length > 0 ? (PicData.indexOf('http') === 0 ? PicData : `data:image/png;base64,${PicData}`) : "";
                                                    let imgPic = PicFullData.length > 0 ? (PicFullData.indexOf('http') === 0 ? PicFullData : `data:image/png;base64,${PicFullData}`) : "";
                                                    return (
                                                        <TableRow key={id} hover>
                                                            <TableCell align="left" sx={{ minWidth: 60 }}>{pageC + index + 1}</TableCell>
                                                            <TableCell align="left">
                                                                <div>{SN}</div>
                                                                <div style={{ color: '#979797' }}>{UpTime}</div>
                                                            </TableCell>
                                                            {/* <TableCell align="left">{Title}</TableCell> */}
                                                            <TableCell sx={{
                                                                width: 600,
                                                            }} style={{
                                                                'word-break': 'break-all',
                                                                // 'overflow': 'hidden',
                                                                // 'text-overflow': 'ellipsis',
                                                                // 'display': '-webkit-box',
                                                                // '-webkit-box-orient': 'vertical',
                                                                // '-webkit-line-clamp': '2',
                                                            }} align="left">
                                                                <b>{Title}</b>
                                                                <ElseMsgView ElseJsonData={ElseJson} />
                                                                <div style={{ color: '#979797' }}>{Content}</div>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {/* {(PicFullData && PicFullData.length > 0) ?
                                                                <a target={'_blank'} href={imgPic}><img height='30' src={imgPic} /></a> :
                                                                ((PicData && PicData.length > 0) ?
                                                                    <a target={'_blank'} href={imgPic0}><img height='30' src={imgPic0} /></a> : null)
                                                            } */}
                                                                {/* <Stack spacing={1}> */}
                                                                {imgPic0 && <a target={'_blank'} href={imgPic0}><img height='81' src={imgPic0} /></a>}
                                                                {/* </Stack> */}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {imgPic && <a target={'_blank'} href={imgPic}><img width='220' src={imgPic} /></a>}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        }
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={-1}
                                component="div"
                                count={pageData.totalCount}
                                rowsPerPage={pageData.pageSize}
                                page={pageData.pageIndex}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )
                }}
            </Pager>
        </Card >
    );
}

const ElseMsgView = ({ ElseJsonData }) => {

    if (ElseJsonData && ElseJsonData.indexOf("{") === 0) {
        let { User, CheWei, OrderNumber, OrderMessageId } = JSON.parse(ElseJsonData);
        return <Stack direction={'row'} spacing={1}>
            <div>{`用户：${User}`}</div>
            <div>{`车位：${CheWei}`}</div>
            {OrderNumber && <div>{`订单：${OrderNumber}-${OrderMessageId}`}</div>}
        </Stack>
    }
    return null;
}
