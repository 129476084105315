import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';

import React, { useState, useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function CheckEnv() {
  let navigate = useNavigate();
  const [_computerSN, set_ComputerSN] = useState('');

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  const readComputerSN = async () => {
    for (var i = 1; i <= 15; i++) {
      try {
        var computerSN = document.computerSN;
        console.log(`读取第${i}次`, 'document.computerSN', computerSN);
        //alert(i + ':computerSN:' + computerSN);
        if (i >= 10 && global.isDevelopment) {
          const moniComputerSN = '123456789*0';
          console.log('开发模式下，模拟一个', moniComputerSN);
          //set_ComputerSN(moniComputerSN)
          reactLocalStorage.set('localStorage_computerSN', moniComputerSN);
          gotoPage(moniComputerSN);
          break;
        }
        else if (computerSN != null && computerSN != undefined && computerSN.length > 0) {
          //console.log('computerSN', computerSN);
          //alert(computerSN);
          //set_ComputerSN(computerSN)
          reactLocalStorage.set('localStorage_computerSN', computerSN);
          gotoPage(computerSN);
          break;
        }
      } catch (e) { console.log('document.computerSN', e) }

      if (i >= 15) {
        //没有获取到，也可以进去
        var computerSN = 'null';
        reactLocalStorage.set('localStorage_computerSN', computerSN);
        gotoPage(computerSN);
        break;
      }

      await sleep(500);
    }
  }

  useEffect(() => {
    // let localStorage_computerSN = reactLocalStorage.get('localStorage_computerSN', null);
    // if (localStorage_computerSN != null && localStorage_computerSN.length > 0) {
    //   console.log('localStorage_computerSN', localStorage_computerSN);
    //   //set_ComputerSN(localStorage_computerSN)
    //   gotoPage(localStorage_computerSN);
    // }
    // else
    readComputerSN();
  }, []);

  const gotoPage = (computerSN) => {
    set_ComputerSN(computerSN);
    setTimeout(() => {
      console.log('gotoPage', 'checkCompanyCode')
      navigate("/checkCompanyCode", { replace: true, state: { computerSN } });
    }, 1000)
  }


  return (
    <RootStyle title="Login | Minimal-UI">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              系统正在准备中，请稍后！
            </Typography>
            {
              _computerSN.length > 0 ?
                <Typography sx={{ color: 'text.secondary' }}>{_computerSN}</Typography>
                :
                <Typography sx={{ color: 'text.secondary' }}>数据读取中...</Typography>
            }
          </Stack>
          {/* <AuthSocial /> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
