import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import Usera1 from './pages/Usera1';
import NotFound from './pages/Page404';
//
import ProfileLayout from './pages/Profile/ProfileLayout'
import Profile from './pages/Profile/Profile';
import Safe from './pages/Profile/Safe';
import Pwd from './pages/Profile/Pwd';
import BindMobile from './pages/Profile/BindMobile';
import ChangeMobile from './pages/Profile/ChangeMobile';
import BindEmail from './pages/Profile/BindEmail';
import ChangeEmail from './pages/Profile/ChangeEmail';
import SetLayout from './pages/Set/SetLayout';
import Account from './pages/Set/Account';
import CreateAccount from './pages/Set/CreateAccount';
import EditAccount from './pages/Set/EditAccount';
import Authorization from './pages/Set/Authorization';
import Door from './pages/Set/Door';
import CreateDoor from './pages/Set/CreateDoor';
import DetailAuthorization from './pages/Set/DetailAuthorization';
import Payment from './pages/Set/Payment';
import IntegralRule from './pages/Set/IntegralRule';
import OwnerLayout from './pages/Owner/Layout';
import Owner from './pages/Owner';
import OwnerImport from './pages/Owner/OwnerImport';
import EditOwner from './pages/Owner/EditOwner';
import Integral from './pages/Owner/Integral';
import IntegralLog from './pages/Owner/IntegralLog';
import ParkingSpaceLayout from './pages/ParkingSpace/Layout';
import ParkingSpace from './pages/ParkingSpace';
import ParkingSpaceImport from './pages/ParkingSpace/ParkingSpaceImport';
import EditParkingSpace from './pages/ParkingSpace/EditParkingSpace';
import SetParkingSpacePrice from './pages/ParkingSpace/SetParkingSpacePrice';
import SetParkingSpaceOrderParam from './pages/ParkingSpace/SetParkingSpaceOrderParam';
import SetParkingSpaceTime from './pages/ParkingSpace/SetParkingSpaceTime';
import SetParkingSpaceTimeAdd from './pages/ParkingSpace/SetParkingSpaceTimeAdd';
import ParkingSpaceBill from './pages/ParkingSpace/Bill';
import ParkingSpaceOrder from './pages/ParkingSpace/Order';
import ParkingSpaceOrderDetail from './pages/ParkingSpace/OrderDetail';
import ProductLayout from './pages/Product/Layout';
import Product from './pages/Product';
import ProductAdd from './pages/Product/Add';
import ProductSort from './pages/Product/Sort';
import ProductAddSort from './pages/Product/AddSort';
import ProductOrder from './pages/Product/Order';
import ProductOrderDetail from './pages/Product/OrderDetail';
import ProductBill from './pages/Product/Bill';
import DynamicMessageLayout from './pages/DynamicMessage/Layout';
import DynamicMessageList from './pages/DynamicMessage/DynamicMessageList';
import DynamicMessageAdd from './pages/DynamicMessage/DynamicMessageAdd';
import DynamicMessageComment from './pages/DynamicMessage/DynamicMessageComment';
import FeedbackWorkOrderList from './pages/DynamicMessage/FeedbackWorkOrderList';
import FeedbackWorkOrderReply from './pages/DynamicMessage/FeedbackWorkOrderReply';
import PublicityList from './pages/DynamicMessage/PublicityList';
import PublicityAdd from './pages/DynamicMessage/PublicityAdd';
import BicycleLayout from './pages/Bicycle/Layout';
import BicycleList from './pages/Bicycle';
import BicycleDetail from './pages/Bicycle/Detail';
import BicycleInOutLogList from './pages/Bicycle/LogList';
import VisitorOrderLayout from './pages/VisitorOrder/Layout';
import VisitorOrderList from './pages/VisitorOrder';
import VisitorOrderDetail from './pages/VisitorOrder/Detail';
import VisitorOrderInOutLogList from './pages/VisitorOrder/LogList';
import CollectLayout from './pages/Collect/Layout';
import CollectBill from './pages/Collect/Bill';
import QRCode from './pages/Collect/qrCode';
import DeviceLayout from './pages/Device/Layout';
import DeviceList from './pages/Device/DeviceList';
import DeviceAdd from './pages/Device/DeviceAdd';
import DeviceLogList from './pages/Device/DeviceLogList';
import DoorCardList from './pages/Device/DoorCardList';
import DoorCardAdd from './pages/Device/DoorCardAdd';

// ----------------------------------------------------------------------
import CheckEnv from './pages/CheckEnv';
import LoginIn from './pages/LoginIn';
import CheckCompanyCode from './pages/CheckCompanyCode';
import ShangHu from './pages/Set/ShangHu';
import CreateShangHu from './pages/Set/CreateShangHu';

// ----------------------------------------------------------------------
import BlackList from './pages/BlackList/List';
import BlackListAdd from './pages/BlackList/Add';

import PzltParam from './pages/ParkingSpace/PzltParam';

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        {
          path: 'profile', element: <ProfileLayout />, children: [
            { path: 'app', element: <Profile /> },
            { path: 'safe', element: <Safe /> },
            { path: 'safe/pwd', element: <Pwd /> },
            { path: 'safe/bindmobile/:oldCheckMsg', element: <BindMobile /> },
            { path: 'safe/changemobile/:oldMobile', element: <ChangeMobile /> },
            { path: 'safe/bindemail/:oldCheckMsg', element: <BindEmail /> },
            { path: 'safe/changeemail/:oldEmail', element: <ChangeEmail /> },
          ]
        },
        {
          path: 'set', element: <SetLayout />, children: [
            { path: 'account', element: <Account /> },
            { path: 'account/create', element: <CreateAccount /> },
            { path: 'account/edit/:id', element: <EditAccount /> },
            { path: 'authorization', element: <Authorization /> },
            { path: 'authorization/detail/:id', element: <DetailAuthorization /> },
            { path: 'payment', element: <Payment /> },
            { path: 'integralrule', element: <IntegralRule /> },
            { path: 'door', element: <Door /> },
            { path: 'door/create', element: <CreateDoor /> },
            { path: 'door/create/:id', element: <CreateDoor /> },
            { path: 'shanghu', element: <ShangHu /> },
            { path: 'shanghu/create', element: <CreateShangHu /> },
            { path: 'shanghu/create/:id', element: <CreateShangHu /> },
          ]
        },
        {
          path: 'collect', element: <CollectLayout />, children: [
            { path: 'qrCode', element: <QRCode /> },
            { path: 'bill', element: <CollectBill /> }
          ]
        },
        {
          path: 'owner', element: <OwnerLayout />, children: [
            { path: 'list', element: <Owner /> },
            { path: 'list/import', element: <OwnerImport /> },
            { path: 'list/edit/:id', element: <EditOwner /> },
            { path: 'integral', element: <Integral /> },
            { path: 'integral/log/:id', element: <IntegralLog /> },
          ]
        },
        {
          path: 'parkingspace', element: <ParkingSpaceLayout />, children: [
            { path: 'list', element: <ParkingSpace /> },
            { path: 'list/import', element: <ParkingSpaceImport /> },
            { path: 'list/edit/:id', element: <EditParkingSpace /> },
            { path: 'setparkingspaceprice', element: <SetParkingSpacePrice /> },
            { path: 'setparkingspaceorderparam', element: <SetParkingSpaceOrderParam /> },
            { path: 'setparkingspacetime', element: <SetParkingSpaceTime /> },
            { path: 'setparkingspacetime/add', element: <SetParkingSpaceTimeAdd /> },
            { path: 'setparkingspacetime/edit/:id', element: <SetParkingSpaceTimeAdd /> },
            { path: 'bill', element: <ParkingSpaceBill /> },
            { path: 'order', element: <ParkingSpaceOrder /> },
            { path: 'order/detail/:id', element: <ParkingSpaceOrderDetail /> },

            { path: 'blacklist', element: <BlackList /> },
            { path: 'blacklist/add', element: <BlackListAdd /> },
            { path: 'blacklist/edit/:id', element: <BlackListAdd /> },

            { path: 'pzlt/pzltparam', element: <PzltParam /> },
          ]
        },
        {
          path: 'bicycle', element: <BicycleLayout />, children: [
            { path: 'list', element: <BicycleList /> },
            { path: 'list/detail/:id', element: <BicycleDetail /> },
            { path: 'inoutlog', element: <BicycleInOutLogList /> },
            { path: 'inoutlog/:number', element: <BicycleInOutLogList /> },
          ]
        },
        {
          path: 'visitororder', element: <VisitorOrderLayout />, children: [
            { path: 'list', element: <VisitorOrderList /> },
            { path: 'list/detail/:id', element: <VisitorOrderDetail /> },
            { path: 'inoutlog', element: <VisitorOrderInOutLogList /> },
            { path: 'inoutlog/:number', element: <VisitorOrderInOutLogList /> },
          ]
        },
        {
          path: 'product', element: <ProductLayout />, children: [
            { path: 'list', element: <Product /> },
            { path: 'list/add', element: <ProductAdd /> },
            { path: 'list/edit/:id', element: <ProductAdd /> },
            { path: 'list/sort', element: <ProductSort /> },
            { path: 'list/sort/add', element: <ProductAddSort /> },
            { path: 'list/sort/edit/:id', element: <ProductAddSort /> },
            { path: 'order', element: <ProductOrder /> },
            { path: 'order/detail/:id', element: <ProductOrderDetail /> },
            { path: 'bill', element: <ProductBill /> },
          ]
        },
        {
          path: 'dynamicmessage', element: <DynamicMessageLayout />, children: [
            { path: 'dynamicmessage', element: <DynamicMessageList /> },
            { path: 'dynamicmessage/add', element: <DynamicMessageAdd /> },
            { path: 'dynamicmessage/edit/:id', element: <DynamicMessageAdd /> },
            { path: 'dynamicmessage/comment/:id', element: <DynamicMessageComment /> },

            { path: 'feedbackworkorder', element: <FeedbackWorkOrderList /> },
            { path: 'feedbackworkorder/reply/:id', element: <FeedbackWorkOrderReply /> },

            { path: 'publicity', element: <PublicityList /> },
            { path: 'publicity/add', element: <PublicityAdd /> },
            { path: 'publicity/edit/:id', element: <PublicityAdd /> },
          ]
        },
        {
          path: 'device', element: <DeviceLayout />, children: [
            { path: 'list', element: <DeviceList /> },
            { path: 'list/add', element: <DeviceAdd /> },
            { path: 'list/edit/:id', element: <DeviceAdd /> },
            { path: 'log', element: <DeviceLogList /> },
            { path: 'doorcard/list', element: <DoorCardList /> },
            { path: 'doorcard/list/add', element: <DoorCardAdd /> },
            { path: 'doorcard/list/edit/:id', element: <DoorCardAdd /> },
          ]
        },

        /////////////
        {
          path: 'user', element: <User />, children: [
            { path: 'a1', element: <Usera1 /> },
            { path: 'a2', element: <Usera1 /> },
          ]
        },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: '', element: <Navigate to="/dashboard/app" /> },
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'loginIn/:token', element: <LoginIn /> },
        { path: 'checkEnv', element: <CheckEnv /> },
        { path: 'checkCompanyCode', element: <CheckCompanyCode /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        /* { path: '/', element: <Navigate to="/dashboard/app" /> }, */
        { path: '/', element: <Navigate to="/checkEnv" /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
