import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem, Switch
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function SetParkingSpaceOrderParam() {
  const navigete = useNavigate();
  const [setTools] = useOutletContext();

  const loadData = async () => {
    var result = await request.GetData('Web/Office/ParkingSpace/GetParkingSpaceOrderParam', {})
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    setTools([

    ]);
    loadData();
  }, [])

  const LoginSchema = Yup.object().shape({
    MaxAdvanceYuDingMinutes: Yup.number().integer('必须为整数').required('最大提前预定分钟 必填'),
    MaxOvertimeAdmissionMinitesFinishOrder_Price: Yup.number().integer('必须为整数').required('超时未入场结束订单收取耗时费 必填'),
    OpenYeZhuShareCheWei: Yup.boolean().nullable(),
    NewEnergyFreeHour: Yup.number().integer('必须为整数').required('必填'),
  });

  const formik = useFormik({
    initialValues: {
      MaxAdvanceYuDingMinutes: '120',
      MaxOvertimeAdmissionMinitesFinishOrder_Price: '1',
      OpenYeZhuShareCheWei: null,
      NewEnergyFreeHour: 0,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = await request.PostJsonData('Web/Office/ParkingSpace/UpdateParkingSpaceOrderParam', data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      window.location.href = window.location.href;
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;
  // console.log('touched', touched)

  return (
    <Card>
      {/* <CardHeader title="公共车位" subheader="" /> */}
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      name='OpenYeZhuShareCheWei'
                      color="primary"
                      checked={values.OpenYeZhuShareCheWei}
                      onChange={handleChange}
                    />
                  }
                  label="允许业主共享车位"
                  labelPlacement="end"
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="MaxAdvanceYuDingMinutes"
                  type="number"
                  label="最大提前预定分钟"
                  {...getFieldProps('MaxAdvanceYuDingMinutes')}
                  error={Boolean(touched.MaxAdvanceYuDingMinutes && errors.MaxAdvanceYuDingMinutes)}
                  helperText={touched.MaxAdvanceYuDingMinutes && errors.MaxAdvanceYuDingMinutes}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="MaxOvertimeAdmissionMinitesFinishOrder_Price"
                  type="number"
                  label="超时未入场结束订单收取耗时费"
                  {...getFieldProps('MaxOvertimeAdmissionMinitesFinishOrder_Price')}
                  error={Boolean(touched.MaxOvertimeAdmissionMinitesFinishOrder_Price && errors.MaxOvertimeAdmissionMinitesFinishOrder_Price)}
                  helperText={touched.MaxOvertimeAdmissionMinitesFinishOrder_Price && errors.MaxOvertimeAdmissionMinitesFinishOrder_Price}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="NewEnergyFreeHour"
                  type="number"
                  label="新能源车免费几小时"
                  {...getFieldProps('NewEnergyFreeHour')}
                  error={Boolean(errors.NewEnergyFreeHour)}
                  helperText={errors.NewEnergyFreeHour ?? '填入0表示关闭新能源免费'}
                />
              </Stack>

              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                保存
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
