import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Paper,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem, Switch,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput, getHelperAndErrorProps, RichText } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const CSelect = (props) => {
  let { labelId, label, source, dealData } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      let data = dealData != undefined ? dealData(result.Result) : result.Result.data;
      setData(data);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.Name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}


export default function ProductAdd() {
  const theme = useTheme();
  console.log('theme', theme);
  const navigete = useNavigate();
  const [setTools] = useOutletContext();
  let { id } = useParams();
  let add = id === undefined;

  const loadData = async () => {
    var result = await request.GetData('Web/Office/Product/GetOne?id=' + id, {})
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    if (!add) {
      setTools([
        {
          title: '删除',
          icon: 'carbon:delete',
          onClick: async () => {
            if (window.confirm('确定要删除吗？')) {
              const result = await request.PostJsonData('Web/Office/Product/Delete?id=' + id, {});
              if (result.Code === 1) {
                global.addMessage(result.Message);
                global.myHistory.back();
                return true;
              }
              else
                global.addMessage(result.Message);
              return false;
            }
          },
        }
      ]);
      loadData();
    }
  }, [])

  const LoginSchema = Yup.object().shape({
    PropertyProductType: Yup.string().required('类型 必填'),
    PropertyProductSortId: Yup.number().required('分类 必填').nullable(),
    Title: Yup.string().required('标题 必填'),
    SubTitle: Yup.string(),
    Pics: Yup.array(),
    Content: Yup.string(),
    PPST: Yup.array().of(
      Yup.object().shape({
        Code: Yup.string().required('货号 必填'),
        Name: Yup.string().required('名称 必填'),
        Integral: Yup.number().required('兑换积分 必填').nullable(),
        Price: Yup.number().required('售卖价格 必填').nullable(),
        MarketPrice: Yup.number().required('市场价格 必填').nullable(),
        Count: Yup.number().required('库存 必填').nullable(),
        Pic: Yup.string().required('规格图片 必填'),
        //Show:Yup.bool().required('显示 必填'),
      })
    )
  });

  const formik = useFormik({
    initialValues: {
      PropertyProductType: '',
      PropertyProductSortId: '',
      Title: '',
      SubTitle: '',
      Pics: [],
      Content: '',
      PPST: [{
        Code: '',
        Name: '',
        Integral: null,
        Price: null,
        MarketPrice: null,
        Count: 100,
        Pic: '',
        Show: true,
      }]
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = add ?
      await request.PostJsonData('Web/Office/Product/Create', data) :
      await request.PostJsonData('Web/Office/Product/Edit?id=' + id, data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      //navigete('/dashboard/ParkingSpace/list', { replace: true });
      global.myHistory.back();
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps, setFieldValue } = formik;
  console.log('formik', formik)
  console.log('touched', touched)

  return (
    <Card>
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>

              <Stack direction='row' spacing={3}>
                <CSelect
                  key={'PropertyProductType'}
                  autoComplete="PropertyProductType"
                  labelId='PropertyProductType'
                  label='类型'
                  source='/Public/GetEnumValsByGet?EnumName=models.Product.PropertyProductType&OnlyText=true'
                  {...getFieldProps('PropertyProductType')}
                  error={Boolean(touched.PropertyProductType && errors.PropertyProductType)}
                  helperText={touched.PropertyProductType && errors.PropertyProductType}
                  dealData={(data) => {
                    return data.map(item => ({ id: item.id, Name: item.name }));
                  }}
                />

                <CSelect
                  key={'PropertyProductSortId'}
                  autoComplete="PropertyProductSortId"
                  labelId='PropertyProductSortId'
                  label='分类'
                  source='/Web/Office/Product/GetPropertyProductSortList?pageSize=9999'
                  {...getFieldProps('PropertyProductSortId')}
                  error={Boolean(touched.PropertyProductSortId && errors.PropertyProductSortId)}
                  helperText={touched.PropertyProductSortId && errors.PropertyProductSortId}
                />
              </Stack>

              <TextField
                autoComplete="Title"
                type="text"
                label="标题"
                {...getFieldProps('Title')}
                {...getHelperAndErrorProps(formik, 'Title')}
                sx={{ minWidth: 500 }}
              />

              <TextField
                autoComplete="SubTitle"
                type="text"
                label="副标题"
                {...getFieldProps('SubTitle')}
                sx={{ minWidth: 500 }}
              />

              <UpLoadInput title='图片' name='Pics' multiple formik={formik} />

              <Typography variant='caption' px={2}>规格</Typography>
              <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.grey[100] }} style={{ marginTop: 4 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>货号</TableCell>
                      <TableCell >名称</TableCell>
                      <TableCell >积分</TableCell>
                      <TableCell >价格</TableCell>
                      <TableCell >市场价</TableCell>
                      <TableCell >库存</TableCell>
                      <TableCell >图片</TableCell>
                      <TableCell >展示/操作</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.PPST.map((row, rowIndex) => (
                      <TableRow
                        key={`row${row.rowIndex}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>
                          <TextField
                            type="text"
                            {...getFieldProps(`PPST.${rowIndex}.Code`)}
                            {...getHelperAndErrorProps(formik, `PPST[${rowIndex}].Code`)}
                          />
                        </TableCell>
                        <TableCell >
                          <TextField
                            type="text"
                            {...getFieldProps(`PPST.${rowIndex}.Name`)}
                            {...getHelperAndErrorProps(formik, `PPST[${rowIndex}].Name`)}
                          />
                        </TableCell>
                        <TableCell >
                          <TextField
                            type="number"
                            {...getFieldProps(`PPST.${rowIndex}.Integral`)}
                            {...getHelperAndErrorProps(formik, `PPST[${rowIndex}].Integral`)}
                          />
                        </TableCell>
                        <TableCell >
                          <TextField
                            type="number"
                            {...getFieldProps(`PPST.${rowIndex}.Price`)}
                            {...getHelperAndErrorProps(formik, `PPST[${rowIndex}].Price`)}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            {...getFieldProps(`PPST.${rowIndex}.MarketPrice`)}
                            {...getHelperAndErrorProps(formik, `PPST[${rowIndex}].MarketPrice`)}
                          />
                        </TableCell>
                        <TableCell >
                          <TextField
                            type="number"
                            {...getFieldProps(`PPST.${rowIndex}.Count`)}
                            {...getHelperAndErrorProps(formik, `PPST[${rowIndex}].Count`)}
                          />
                        </TableCell>
                        <TableCell sx={{ minWidth: 250 }}>
                          <UpLoadInput name={`PPST[${rowIndex}].Pic`} formik={formik} width={40} height={40} />
                        </TableCell>
                        <TableCell >
                          <Switch
                            name={`PPST.${rowIndex}.Show`}
                            checked={values.PPST[rowIndex].Show}
                            onChange={handleChange}
                          />
                          <Button variant="text" color={'error'}
                            onClick={() => {
                              var newValues = { ...formik.values };
                              if (newValues.PPST.length <= 1) {
                                global.addMessage('至少需要保留一个规格');
                              }
                              else {
                                newValues.PPST.splice(rowIndex, 1);
                                formik.setValues(newValues);
                              }
                            }}
                          >删除</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={`row-add`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        <Button variant="text" onClick={() => {
                          var newValues = { ...formik.values };
                          newValues.PPST.push({
                            Code: '',
                            Name: '',
                            Integral: null,
                            Price: null,
                            MarketPrice: null,
                            Count: 100,
                            Pic: '',
                            Show: true,
                          });
                          formik.setValues(newValues);
                        }}>增加</Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <Typography variant='caption' px={2}>介绍</Typography>
              <Paper variant="outlined" sx={{ width: '100%' }} style={{ marginTop: 4 }}>
                <textarea style={{ resize: 'none', width: '100%', minHeight: 300, border: 'none', padding: 8, borderRadius: 8 }}
                  {...getFieldProps('Content')}
                  {...getHelperAndErrorProps(formik, `Content`)}
                ></textarea>
              </Paper> */}
              <RichText name='Content' formik={formik} />

              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton

                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                保存
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
