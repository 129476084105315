import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';

var defaultSecond = 60;
var timer = null;
var jishi = null;
export default function BindEmail() {
  var navigate = useNavigate();
  let { oldCheckMsg } = useParams();

  let [js, setJs] = useState(null);
  let [jishi_ing, setJishi_ing] = useState(false);

  useEffect(() => {
    if (global.isDevelopment) defaultSecond = 10;
  }, [])

  const LoginSchema = Yup.object().shape({
    Email: Yup.string().required('邮箱号 必填'),
    CheckCode: Yup.string().required('验证码 必填')
  });

  const formik = useFormik({
    initialValues: {
      Email: '',
      CodeCiphertext: '',
      CheckCode: '',
      oldCheckMsg: oldCheckMsg
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    if (values.CodeCiphertext.length < 1) {
      global.addMessage('请先获取验证码');
      return false;
    }

    const data = values;
    const result = await request.PostFormData('Web/Public/UpdateLoginEmail', data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      navigate('/dashboard/profile/safe', { replace: true });
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const jianMiao = () => {
    jishi--;
    setJs(jishi);
    if (jishi <= 0) {
      clearInterval(timer);
      setJishi_ing(false);
    }
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

  const sendCode = async () => {
    if (!jishi_ing) {
      //调用接口发送验证码
      const result = await request.PostFormData('Web/Public/SendMsg', { Email: values.Email });
      if (result.Code === 1) {

        //CodeCiphertext = result.Result;
        formik.setFieldValue('CodeCiphertext', result.Result);

        jishi = defaultSecond;
        setJs(jishi);
        setJishi_ing(true);

        timer = setInterval(() => {
          jianMiao();
        }, 1000);
      }
      else
        global.addMessage(result.Message);
    }
  }



  return (
    <Card>
      <CardHeader title="绑定新邮箱" subheader="" />
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <TextField
                autoComplete="Email"
                type="text"
                label="邮箱号"
                {...getFieldProps('Email')}
                error={Boolean(touched.Email && errors.Email)}
                helperText={touched.Email && errors.Email}
                sx={{ width: 300 }}
              />

              <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                <TextField
                  autoComplete="CheckCode"
                  type="text"
                  label="验证码"
                  {...getFieldProps('CheckCode')}
                  error={Boolean(touched.CheckCode && errors.CheckCode)}
                  helperText={touched.CheckCode && errors.CheckCode}
                  sx={{ width: 200 }}
                />
                <Button disabled={jishi_ing} onClick={() => { sendCode() }}>{jishi_ing ? `${js} 秒` : '发送验证码'}</Button>
              </Stack>


              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton

                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                绑定
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
