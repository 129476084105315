import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';

import React, { useState, useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useNavigate, useParams } from "react-router-dom";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function LoginIn() {
  let navigate = useNavigate();
  let { token } = useParams();

  const [err, setErr] = useState(false);
  useEffect(() => {
    if (token && token.length > 0) {
      reactLocalStorage.set('Authorization', token);
      navigate('/dashboard', { replace: true });
    }
    else {
      setErr(true)
    }
  }, []);

  return (
    <RootStyle title="Login | Minimal-UI">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            {!err ?
              <Typography variant="h4" gutterBottom>
                检测中...
              </Typography>
              :
              <Typography variant="h4" gutterBottom>
                失败！
              </Typography>
            }
          </Stack>
          {/* <AuthSocial /> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
