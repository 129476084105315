import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';

import { GetUserInfo } from './index';
import { useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  /* {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  }, */
  {
    label: '资料',
    icon: personFill,
    linkTo: '/dashboard/profile/app'
  }
];
const MENU_OPTIONS2 = [ //管理员才有的
  {
    label: '设置',
    icon: settings2Fill,
    linkTo: '/dashboard/set/account'
  }
];
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    GetUserInfo().then(userInfo => {
      if (userInfo != null) {
        //let { id, Account, NickName, Mobile, Mobile2, Email, HeadPortrait, IsMain } = ;
        setUserInfo(JSON.parse(userInfo.amr[0]))
      }
    });
  }, []);

  if (userInfo == null) return null;
  //console.log('userInfo 123', userInfo);

  //var role = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
  var isAdmin = userInfo.Claim.indexOf("admin") >= 0;

  const GetUserNick = () => {
    var u = userInfo;
    if (u.NickName.length > 0) return u.NickName;
    if (u.Mobile.length > 0) return u.Mobile;
    if (u.Email.length > 0) return u.Email;
    if (u.Mobile2.length > 0) return u.Mobile2;
    return 'No Name';
  }

  const GetUserID = () => {
    var u = userInfo;
    if (u.Mobile.length > 0) return u.Mobile;
    if (u.Email.length > 0) return u.Email;
    if (u.Mobile2.length > 0) return u.Mobile2;
    return 'No Key ID';
  }

  const logout = () => {
    if (window.confirm('确定要退出登录吗？'))
      try {
        reactLocalStorage.remove('Authorization');
        console.log("Remove authorization And Navigate to '/'");
        console.log("**************logout**************");
        global.navigate && global.navigate('/', { replace: true });
      } catch (e) { }
    else {
      handleClose();
    }
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], theme.palette.action.focusOpacity)
            }
          })
        }}
      >
        <Avatar src={userInfo.HeadPortrait} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems='center' sx={{ my: 1.5, px: 2.5 }}>
          <Avatar src={userInfo.HeadPortrait} />
          <Box sx={{ overflow: 'hidden' }}>
            <Typography variant="subtitle1" noWrap>
              {GetUserNick()}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {GetUserID()}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.concat(isAdmin ? MENU_OPTIONS2 : []).map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button onClick={() => { logout() }} fullWidth color="inherit" variant="outlined">
            注销
          </Button>
        </Box>
      </MenuPopover>
    </>
  );

}
