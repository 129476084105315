import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext, useParams } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function Header({ numSelected, submit, loading = false }) {

    const LoginSchema = Yup.object().shape({
        Key: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            Key: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            console.log('values', values);
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>

                            <Stack direction={'row'}>
                                <TextField
                                    type="text"
                                    label="业主/手机号/房号"
                                    {...getFieldProps('Key')}
                                />
                                <Stack flex={1}></Stack>
                            </Stack>

                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function IntegralLog() {
    const [setTools] = useOutletContext();
    const theme = useTheme(); console.log('theme', theme)
    let { id } = useParams();

    const [open, setOpen] = React.useState(false);
    const [integral, setIntegral] = useState("");
    const [remark, setRemark] = useState("");

    const TABLE_HEAD = [
        { label: '序号', id: 'Num' },
        { label: '标题', id: 'Title' },
        { label: '积分', id: 'IntegralNum' },
        { label: '明细', id: 'Content' },
        { label: '时间', id: 'AtTime' },
    ];

    useEffect(() => {
        setTools([
            {
                title: '增/减积分',
                icon: 'carbon:add',
                onClick: async () => {
                    handleClickOpen();
                }
            }
        ]);
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfim = async () => {
        if (window.confirm('确定要继续操作吗？')) {
            let data = { userId: id, integral: integral, remark: remark }
            const result = await request.PostFormData('Web/Office/Owner/OperateIntegral', data);
            if (result.Code === 1) {
                global.addMessage(result.Message);
                window.location.href = window.location.href;

                setOpen(false);
                setIntegral("");
                setRemark("");
                return true;
            }
            else
                global.addMessage(result.Message);
            return false;
        }
    }

    return (
        <Card>
            <Pager url='/Web/Office/Owner/IntegralLog' sourceParams={{ userId: id }} pageSize={50}>
                {({ pageData, handleChangePage, searchSubmit, loading }) => {
                    return (
                        <>
                            {/* <Header numSelected={0} submit={searchSubmit} loading={loading} /> */}
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                    <TableCell align={headCell.align ?? 'inherit'}>
                                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pageData.data.map((row, index) => {
                                                const { id, IntegralNum, Title, Content, AtTime } = row
                                                return (
                                                    <TableRow hover>
                                                        <TableCell align="left">{index + 1}</TableCell>
                                                        <TableCell align="left">{Title}</TableCell>
                                                        <TableCell align="left">{IntegralNum}</TableCell>
                                                        <TableCell align="left">{Content}</TableCell>
                                                        <TableCell align="left">{AtTime}</TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={-1}
                                component="div"
                                count={pageData.totalCount}
                                rowsPerPage={pageData.pageSize}
                                page={pageData.pageIndex}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )
                }}
            </Pager>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>增/减积分</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="integral"
                        label="积分"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={integral}
                        onChange={(e) => { setIntegral(e.target.value) }}
                    />
                    <TextField
                        margin="dense"
                        id="remark"
                        label="备注"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={remark}
                        onChange={(e) => { setRemark(e.target.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{ color: theme.palette.grey[500] }}>取消</Button>
                    <Button onClick={handleConfim}>确定</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}
