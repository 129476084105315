import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Typography,
    Paper,
    Card, CardHeader,
    Avatar,
    Button, FormControl, Select, InputLabel, MenuItem, Switch,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput, getHelperAndErrorProps, RichText } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';




const CSelect = (props) => {
    let { labelId, label, source } = props;

    let [data, setData] = useState([]);
    const loadData = async () => {
        var result = await request.GetData(source, {})
        if (result.Code === 1) {
            setData(result.Result.data);
        }
        else {

        }
    }

    useEffect(() => {
        loadData();
    }, [source])

    return (
        <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                {...props}
            >
                {data.map((item) => <MenuItem value={item.id}>{item.Name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}


export default function FeedbackWorkOrderComment() {
    const theme = useTheme();
    const navigete = useNavigate();
    const [setTools] = useOutletContext();
    let { id } = useParams();
    const [data, setData] = useState(null);

    const loadData = async () => {
        var result = await request.GetData('Web/Office/DynamicMessage/FeedbackWorkOrderOne?id=' + id, {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else {
            global.addMessage(result.Message);
        }
    }
    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (data != null)
            setTools([
                {
                    title: data.Order.Status === '已关闭' ? '已关闭' : '关闭工单',
                    icon: 'carbon:delete',
                    disabled: data.Order.Status === '已关闭',
                    onClick: data.Order.Status === '已关闭' ? null : async () => {
                        if (window.confirm('确定要关闭工单吗？')) {
                            const result = await request.PostJsonData('Web/Office/DynamicMessage/CloseFeedbackWorkOrder?id=' + id, {});
                            if (result.Code === 1) {
                                global.addMessage(result.Message);
                                global.myHistory.back();
                                return true;
                            }
                            else
                                global.addMessage(result.Message);
                            return false;
                        }
                    },
                }
            ]);
    }, [data])

    const LoginSchema = Yup.object().shape({
        Content: Yup.string().required('内容 必填'),
        Pics: Yup.array(),
        Close: Yup.boolean(),
    });

    const formik = useFormik({
        initialValues: {
            Content: '',
            Pics: [],
            Close: false,
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            //验证
            console.log('values', JSON.stringify(values));
            submit(values).then(result => {
                //if (!result) {
                actions.setSubmitting(false);
                //}
            })
        }
    });

    const submit = async (values) => {
        const data = values;
        const result = await request.PostFormData('Web/Office/DynamicMessage/ReplyFeedbackWorkOrder?id=' + id, data)
        if (result.Code === 1) {
            global.addMessage(result.Message);
            global.myHistory.back();
            return true;
        }
        else
            global.addMessage(result.Message);

        return false;
    }

    const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps, setFieldValue } = formik;

    if (data == null)
        return null
    else
        var pics = (data.Order.Pics === null || data.Order.Pics === '') ? [] : data.Order.Pics.split(',');
    return (
        <Card>
            <Box p={3}>
                <Stack>
                    <Box>
                        <Typography variant="h4" component='div'>工单：{data.Order.Title}</Typography>
                        <Typography variant="caption" component='div'>创建者：{data.Order.UserName && data.Order.NickName} 状态：{data.Order.Status} 创建时间：{data.Order.UpTime}</Typography>
                        <Box p={1}>
                            <Typography variant="body1" component='div' mt={1}>{data.Order.Content}</Typography>
                            <Stack direction={'row'} justifyContent='left' mt={1} spacing={1}>
                                {pics.map(pic =>
                                    <Stack>
                                        <a target='_blank' href={pic}><img height={80} src={pic} /></a>
                                    </Stack>
                                )}
                            </Stack>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        {
                            data.Reply.map((item, index) => {
                                let { UserId, Content, Pics, UpTime } = item.fwor;
                                let { NickName } = item.ub;
                                let { Name } = item.o;
                                var louCeng = '#' + (index + 1);
                                var subPics = (Pics === null || Pics === '') ? [] : Pics.split(',');
                                return (
                                    <Timeline position="right">
                                        <TimelineItem sx={{ ':before': { fontSize: 22, flex: '0 1 auto', content: '"' + louCeng + '"' } }}>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent flex={1}>
                                                <Stack>
                                                    <Stack><Typography variant="subtitle1" >{Content}</Typography></Stack>
                                                    <Stack><Typography variant="caption" >回复者：{UserId > 0 ? (Name && NickName) : '物业'}  提交时间：{UpTime}</Typography></Stack>
                                                    <Stack direction={'row'} justifyContent='left' mt={1} spacing={1}>
                                                        {subPics.map(pic =>
                                                            <Stack>
                                                                <a target='_blank' href={pic}><img height={80} src={pic} /></a>
                                                            </Stack>
                                                        )}
                                                    </Stack>
                                                </Stack>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                )
                            })
                        }
                    </Box>
                </Stack>
                {data.Order.Status === '已关闭' ? null :
                    <Box px={4} py={5}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Stack spacing={3} alignItems={'flex-start'}>

                                    <TextField
                                        autoComplete="Content"
                                        type="text"
                                        label="回复工单"
                                        multiline
                                        rows={4}
                                        {...getFieldProps('Content')}
                                        {...getHelperAndErrorProps(formik, 'Content')}
                                        sx={{ minWidth: '100%' }}
                                    />

                                    <UpLoadInput title='配图' name='Pics' multiple formik={formik} />

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='Close'
                                                color="primary"
                                                checked={values.Close}
                                                onChange={handleChange}
                                            />
                                        }
                                        label="是否关闭工单"
                                        labelPlacement="end"

                                    />

                                    <Stack sx={{ my: 4 }}></Stack>

                                    <LoadingButton

                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        保存
                                    </LoadingButton>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Box>
                }
            </Box>
        </Card >
    );
}
