import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'

export default function Pwd() {


  const LoginSchema = Yup.object().shape({
    OldPwd: Yup.string().required('老密码 必填'),
    NewPwd: Yup.string().required('新密码 必填'),
    NewPwd2: Yup.string().required('新密码 必填')
  });

  const formik = useFormik({
    initialValues: {
      OldPwd: '',
      NewPwd: '',
      NewPwd2: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = await request.PostFormData('Web/Public/UpdateLoginPwd', data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

  const Input = styled('input')({
    display: 'none',
  });

  //console.log('values', values)

  return (
    <Card>
      <CardHeader title="修改密码" subheader="" />
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <TextField
                autoComplete="OldPwd"
                type="password"
                label="老密码"
                {...getFieldProps('OldPwd')}
                error={Boolean(touched.OldPwd && errors.OldPwd)}
                helperText={touched.OldPwd && errors.OldPwd}
              />

              <TextField
                autoComplete="NewPwd"
                type="password"
                label="新密码"
                {...getFieldProps('NewPwd')}
                error={Boolean(touched.NewPwd && errors.NewPwd)}
                helperText={touched.NewPwd && errors.NewPwd}
              />

              <TextField
                autoComplete="NewPwd2"
                type="password"
                label="新密码"
                {...getFieldProps('NewPwd2')}
                error={Boolean(touched.NewPwd2 && errors.NewPwd2)}
                helperText={touched.NewPwd2 && errors.NewPwd2}
              />

              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton

                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                更新
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
