import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { Icon } from '@iconify/react';

export default function Safe() {

  let [data, setData] = useState({});

  const loadData = async () => {
    var result = await request.PostFormData('Web/Public/GetLoginSafe')
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    loadData();
  }, [])

  return (
    <Card>
      <CardHeader title="安全设置" subheader="" />
      <Stack p={3} spacing={5}>
        <Stack>
          <Stack direction={'row'} alignItems={'center'}>
            <Box mr={2}><Icon width='46' icon={'ri:lock-password-line'} /></Box>
            <Stack flex={1}>
              <Typography variant="subtitle1">登录密码</Typography>
              <Typography color={'text.secondary'} variant="caption">为保证您购物安全，建议您定期更改密码以保护账户安全。</Typography>
            </Stack>
            <Button to='/dashboard/profile/safe/pwd' component={RouterLink} variant="outlined">修改</Button>
          </Stack>
        </Stack>

        <Stack>
          <Stack direction={'row'} alignItems={'center'}>
            <Box mr={2}><Icon width='46' icon={'entypo:mobile'} /></Box>
            <Stack flex={1}>
              <Typography variant="subtitle1">绑定手机</Typography>
              <Typography color={'text.secondary'} variant="caption">轻松找回密码，验证身份。{(data.Mobile && data.Mobile.length > 0) ? ("已绑定：" + data.Mobile) : "未绑定"}</Typography>
            </Stack>
            {(data.Mobile && data.Mobile.length > 0) ?
              <Button to={`/dashboard/profile/safe/changemobile/${data.Mobile}`} component={RouterLink} variant="outlined">修改</Button>
              :
              <Button to='/dashboard/profile/safe/bindmobile' component={RouterLink} variant="outlined">绑定</Button>
            }
          </Stack>
        </Stack>

        <Stack>
          <Stack direction={'row'} alignItems={'center'}>
            <Box mr={2}><Icon width='46' icon={'eva:email-outline'} /></Box>
            <Stack flex={1}>
              <Typography variant="subtitle1">绑定邮箱</Typography>
              <Typography color={'text.secondary'} variant="caption">验证身份。{(data.Email && data.Email.length > 0) ? ("已绑定：" + data.Email) : "未绑定"}</Typography>
            </Stack>
            {(data.Email && data.Email.length > 0) ?
              <Button to={`/dashboard/profile/safe/changeemail/${data.Email}`} component={RouterLink} variant="outlined">修改</Button>
              :
              <Button to='/dashboard/profile/safe/bindemail' component={RouterLink} variant="outlined">绑定</Button>
            }
          </Stack>
        </Stack>

      </Stack>
    </Card >
  );
}
