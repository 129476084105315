import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Typography,
    Paper,
    Card, CardHeader,
    Avatar,
    Button, FormControl, Select, InputLabel, MenuItem, Switch,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput, getHelperAndErrorProps, RichText } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cnLocale from 'date-fns/locale/zh-CN';




const CSelect = (props) => {
    let { labelId, label, source } = props;

    let [data, setData] = useState([]);
    const loadData = async () => {
        var result = await request.GetData(source, {})
        if (result.Code === 1) {
            setData(result.Result.data);
        }
        else {

        }
    }

    useEffect(() => {
        loadData();
    }, [source])

    return (
        <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                {...props}
            >
                {data.map((item) => <MenuItem value={item.id}>{item.Name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}


export default function Add() {
    const theme = useTheme();
    //console.log('theme', theme);
    const navigete = useNavigate();
    const [setTools] = useOutletContext();
    let { id } = useParams();
    let add = id === undefined;

    const loadData = async () => {
        var result = await request.GetData('Web/Office/DynamicMessage/PublicityOne?id=' + id, {})
        if (result.Code === 1) {
            formik.setValues(result.Result);
        }
        else {
            global.addMessage(result.Message);
        }
    }
    useEffect(() => {
        if (!add) {
            setTools([
                {
                    title: '删除',
                    icon: 'carbon:delete',
                    onClick: async () => {
                        if (window.confirm('确定要删除吗？')) {
                            const result = await request.PostJsonData('Web/Office/DynamicMessage/DeletePublicity?id=' + id, {});
                            if (result.Code === 1) {
                                global.addMessage(result.Message);
                                global.myHistory.back();
                                return true;
                            }
                            else
                                global.addMessage(result.Message);
                            return false;
                        }
                    },
                }
            ]);
            loadData();
        }
    }, [])

    const LoginSchema = Yup.object().shape({
        Title: Yup.string().required('标题 必填'),
        Content: Yup.string(),
        IsAd: Yup.boolean(),
        Show: Yup.boolean(),
        Fixed: Yup.boolean(),
        StartTime: Yup.date().required('开始时间 必填').nullable(),
        EndTime: Yup.date().required('结束时间 必填').nullable()
    });

    const formik = useFormik({
        initialValues: {
            Title: '',
            Content: '',
            IsAd: false,
            Show: true,
            Fixed: false,
            StartTime: null,
            EndTime: null,
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            //验证
            console.log('values', JSON.stringify(values));
            submit(values).then(result => {
                //if (!result) {
                actions.setSubmitting(false);
                //}
            })
        }
    });

    const submit = async (values) => {
        const data = values;
        const result = add ?
            await request.PostJsonData('Web/Office/DynamicMessage/CreatePublicity', data) :
            await request.PostJsonData('Web/Office/DynamicMessage/UpdatePublicity?id=' + id, data);
        if (result.Code === 1) {
            global.addMessage(result.Message);
            //navigete('/dashboard/ParkingSpace/list', { replace: true });
            global.myHistory.back();
            return true;
        }
        else
            global.addMessage(result.Message);

        return false;
    }

    const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps, setFieldValue } = formik;
    //console.log('formik', formik)
    //console.log('touched', touched)

    return (
        <Card>
            <Box p={3}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3} alignItems={'flex-start'}>

                            <TextField
                                autoComplete="Title"
                                type="text"
                                label="标题"
                                {...getFieldProps('Title')}
                                {...getHelperAndErrorProps(formik, 'Title')}
                                sx={{ minWidth: 500 }}
                            />

                            <RichText name='Content' formik={formik} />

                            <FormControlLabel
                                control={
                                    <Switch
                                        name='IsAd'
                                        color="primary"
                                        checked={values.IsAd}
                                        onChange={handleChange}
                                    />
                                }
                                label="广告"
                                labelPlacement="end"

                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        name='Show'
                                        color="primary"
                                        checked={values.Show}
                                        onChange={handleChange}
                                    />
                                }
                                label="展示"
                                labelPlacement="end"
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        name='Fixed'
                                        color="primary"
                                        checked={values.Fixed}
                                        onChange={handleChange}
                                    />
                                }
                                label="置顶(固定)"
                                labelPlacement="end"
                            />

                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={cnLocale}>
                                <DateRangePicker
                                    startText="开始时间"
                                    endText="结束时间"
                                    value={[values.StartTime, values.EndTime]}
                                    onChange={(newValue) => {
                                        //console.log(newValue);
                                        try {
                                            setFieldValue('StartTime', newValue[0].Format('yyyy-MM-dd'));
                                            setFieldValue('EndTime', newValue[1].Format('yyyy-MM-dd'));
                                        } catch (e) { }
                                    }}
                                    renderInput={(startProps, endProps) => (
                                        <React.Fragment>
                                            <TextField {...startProps}
                                                {...getFieldProps('StartTime')}
                                                {...getHelperAndErrorProps(formik, 'StartTime')}
                                            />
                                            <Box sx={{ mx: 2 }}> 到 </Box>
                                            <TextField {...endProps}
                                                {...getFieldProps('EndTime')}
                                                {...getHelperAndErrorProps(formik, 'EndTime')}
                                            />
                                        </React.Fragment>
                                    )}
                                />
                            </LocalizationProvider>

                            <Stack sx={{ my: 4 }}></Stack>

                            <LoadingButton

                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                保存
                            </LoadingButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Box>
        </Card >
    );
}
