// material
import { Box, Grid, Container, Typography, Divider, Stack } from '@mui/material';

// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
import { reactLocalStorage } from 'reactjs-localstorage';
import request from '../components/request'
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { GetUserInfo } from '../layouts/dashboard/index';

// ----------------------------------------------------------------------

export default function DashboardApp() {

  let [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    GetUserInfo().then(userInfo => {
      if (userInfo != null) {
        //let { id, Account, NickName, Mobile, Mobile2, Email, HeadPortrait, IsMain } = ;
        setUserInfo(JSON.parse(userInfo.amr[0]))
      }
    });
  }, []);

  return (
    <Page title="Dashboard | Minimal-UI">
      <Container maxWidth="xl">
        {(global.isDevelopment && false) &&
          <>
            <Stack direction={'row'}>
              <Box flex={1}>
                <Typography variant="h1" component="div" gutterBottom>
                  h1. Heading
                </Typography>
                <Typography variant="h2" gutterBottom component="div">
                  h2. Heading
                </Typography>
                <Typography variant="h3" gutterBottom component="div">
                  h3. Heading
                </Typography>
                <Typography variant="h4" gutterBottom component="div">
                  h4. Heading
                </Typography>
                <Typography variant="h5" gutterBottom component="div">
                  h5. Heading
                </Typography>
                <Typography variant="h6" gutterBottom component="div">
                  h6. Heading
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="div">
                  subtitle1. Lorem ipsum dolor sit amet
                </Typography>
                <Typography variant="subtitle2" gutterBottom component="div">
                  subtitle2. Lorem ipsum dolor sit amet
                </Typography>
                <Typography variant="body1" gutterBottom>
                  body1. Lorem ipsum dolor sit amet
                </Typography>
                <Typography variant="body2" gutterBottom>
                  body2. Lorem ipsum dolor sit amet
                </Typography>
                <Typography variant="button" display="block" gutterBottom>
                  button text
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  caption text
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                  overline text
                </Typography>
              </Box>
              <Box flex={1}>
                <Typography variant="h1" component="div" gutterBottom>
                  h1. 中文
                </Typography>
                <Typography variant="h2" gutterBottom component="div">
                  h2. 中文
                </Typography>
                <Typography variant="h3" gutterBottom component="div">
                  h3. 中文
                </Typography>
                <Typography variant="h4" gutterBottom component="div">
                  h4. 中文
                </Typography>
                <Typography variant="h5" gutterBottom component="div">
                  h5. 中文
                </Typography>
                <Typography variant="h6" gutterBottom component="div">
                  h6. 中文
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="div">
                  subtitle1. 中文
                </Typography>
                <Typography variant="subtitle2" gutterBottom component="div">
                  subtitle2. 中文
                </Typography>
                <Typography variant="body1" gutterBottom>
                  body1. 中文
                </Typography>
                <Typography variant="body2" gutterBottom>
                  body2. 中文
                </Typography>
                <Typography variant="button" display="block" gutterBottom>
                  button 中文
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  caption 中文
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                  overline 中文
                </Typography>
              </Box>
            </Stack>
            <Divider sx={{ mb: 5 }} />
          </>
        }
        <Box sx={{ pb: 4 }}>
          <Typography variant="h4">控制台</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {/* <AppWebsiteVisits /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <AppCurrentVisits /> */}
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
