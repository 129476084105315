// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import { useState, useLayoutEffect } from 'react';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";


const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });
  useLayoutEffect(() => history.listen(setState), [history]);
  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
const history = createBrowserHistory();
global.myHistory = history;

Date.prototype.Format = function (fmt) { //author: meizz 
  if (fmt == undefined || fmt == null) fmt = 'yy/M/d';
  var o = {
    "M+": this.getMonth() + 1, //月份 
    "d+": this.getDate(), //日 
    "h+": this.getHours(), //小时 
    "m+": this.getMinutes(), //分 
    "s+": this.getSeconds(), //秒 
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
    "S": this.getMilliseconds() //毫秒 
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
String.prototype.Format = function (fmt) {
  try {
    if (this == undefined || this == null) return ''
    return new Date(this).Format(fmt);
  } catch { }
  return ''
}

const _getNODE_ENV = () => {
  try {
    //console.log('process.env.NODE_ENV', process.env.NODE_ENV)
    if (process.env.NODE_ENV !== null && process.env.NODE_ENV !== undefined) {
      //console.log('process.env.NODE_ENV', process.env.NODE_ENV.toString().trim().toLowerCase())
      return process.env.NODE_ENV.toString().trim().toLowerCase() === 'development';
    }
  } catch (e) { }
  return false;
}
global.isDevelopment = _getNODE_ENV();

global.apiUrl = global.isDevelopment ? 'http://localhost:8501' : 'https://sharecarapi.scxiniu.com';
//global.apiUrl = global.isDevelopment ? 'http://192.168.101.107:8501' : 'https://sharecarapi.scxiniu.com';


ReactDOM.render(
  <HelmetProvider>
    <CustomRouter history={history}>
      <App />
    </CustomRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
