import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme, FormControl, Select, InputLabel, MenuItem,
    Chip, Checkbox, FormControlLabel, RadioGroup, Radio
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'

const CSelect = (props) => {
    let { labelId, label, source } = props;

    let [data, setData] = useState([]);
    const loadData = async () => {
        var result = await request.GetData(source, {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else {

        }
    }

    useEffect(() => {
        loadData();
    }, [source])

    return (
        <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                {...props}
            >
                <MenuItem value={''}>全部分类</MenuItem>
                {data.map((item) => <MenuItem value={item.name}>{item.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

function Header({ numSelected, submit, loading = false }) {

    const LoginSchema = Yup.object().shape({
        Status: Yup.string().nullable(),
        Key: Yup.string().nullable(),
        OnlyIn: Yup.boolean(),
        OrderBy: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            Status: '',
            Key: '',
            OnlyIn: false,
            OrderBy: 'Desc'
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            values = { t: Math.random(), ...values };
            console.log('values', values);
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Stack direction={'row'} spacing={2}>
                                <CSelect
                                    labelId='Status'
                                    label='状态'
                                    source='/Public/GetEnumValsByGet?EnumName=models.Community.ParkingSpaceOrderStatus'
                                    {...getFieldProps('Status')}
                                />
                                <TextField
                                    type="text"
                                    label="订单号"
                                    {...getFieldProps('Key')}
                                />
                                <FormControlLabel control={<Checkbox {...getFieldProps('OnlyIn')} checked={values.OnlyIn} />} label="只看场内" />
                            </Stack>

                            <Stack flex={1}></Stack>

                            <Stack>
                                <RadioGroup row value={values.OrderBy} {...getFieldProps('OrderBy')}
                                >
                                    <FormControlLabel value="Desc" control={<Radio />} label="倒序" />
                                    <FormControlLabel value="Asc" control={<Radio />} label="顺序" />
                                </RadioGroup>
                            </Stack>

                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function Index() {
    const [setTools] = useOutletContext();
    const theme = useTheme(); console.log('theme', theme)

    const TABLE_HEAD = [
        { label: '序号', id: 'Num' },
        { label: '订单概要', id: 'GY' },
        { label: '会员/业主', id: 'User' },
        { label: '进出时间', id: 'Product' },
        { label: '车位数量', id: 'Count' },
        { label: '预付金额', id: 'Price', },
        { label: '补差金额', id: 'Price2', },
        { label: '状态', id: 'Status', },
        { label: '操作', id: '', align: 'right' }
    ];

    useEffect(() => {
        setTools([
        ]);
    }, []);

    return (
        <Card>
            <Pager url='/Web/Office/ParkingSpaceOrder/PropertyOrderList'>
                {({ pageData, handleChangePage, searchSubmit, loading }) => {
                    return (
                        <>
                            <Header numSelected={0} submit={searchSubmit} loading={loading} />
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                    <TableCell align={headCell.align ?? 'inherit'}>
                                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pageData.data.map((row, index) => {
                                                const { id, OrderNumber, Property_UserId,
                                                    Buyer_UserId, Buyer_NichName,
                                                    ParkingSpaceCount,
                                                    TotalPrice, TotalBuChaPrice,
                                                    StartTime, EndTime,
                                                    Status, CreateTime, lt } = row;
                                                return (
                                                    <TableRow hover>
                                                        <TableCell align="left">{index + 1}</TableCell>
                                                        <TableCell align="left">
                                                            <Stack direction={'row'}>
                                                                <Stack>
                                                                    <div>{OrderNumber}</div>
                                                                    <div>{CreateTime}</div>
                                                                </Stack>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {lt ? <Chip size='small' label={'临停订单'} color="primary" /> : Buyer_NichName}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div>{StartTime}</div>
                                                            <div>{EndTime}</div>
                                                        </TableCell>
                                                        <TableCell align="left">{ParkingSpaceCount}</TableCell>
                                                        <TableCell align="left">{TotalPrice}</TableCell>
                                                        <TableCell align="left">{TotalBuChaPrice}</TableCell>
                                                        <TableCell align="left">{Status}</TableCell>
                                                        <TableCell align="right">
                                                            <MoreMenu Sub={
                                                                [
                                                                    {
                                                                        label: '明细',
                                                                        icon: <Icon icon={editFill} width={24} height={24} />,
                                                                        to: `/dashboard/parkingspace/order/detail/${id}`
                                                                    },
                                                                ]
                                                            } />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={-1}
                                component="div"
                                count={pageData.totalCount}
                                rowsPerPage={pageData.pageSize}
                                page={pageData.pageIndex}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )
                }}
            </Pager>
        </Card >
    );
}
