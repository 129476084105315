import React, { useState, useEffect } from 'react';
import { Snackbar } from '@mui/material';
import { useNavigate } from "react-router-dom";

global.navigate = null;
global.addMessage = () => { };

export default function GlobalSnackbar() {

    global.navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [notice, setNotice] = useState('');

    global.addMessage = (notice) => {
        setNotice(notice);
        setOpen(true);
    }

    const handleClose = () => {
        setNotice('');
        setOpen(false);
    }

    return (
        <div className="message-container">
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={3500}
                onClose={() => { handleClose() }}
                message={notice}
                sx={{ whiteSpace: 'pre-line' }}
            />
        </div>
    );
}