import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'

const CSelect = (props) => {
    let { labelId, label, source } = props;

    let [data, setData] = useState([]);
    const loadData = async () => {
        var result = await request.GetData(source, {})
        if (result.Code === 1) {
            setData(result.Result);
        }
        else {

        }
    }

    useEffect(() => {
        loadData();
    }, [source])

    return (
        <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                {...props}
            >
                <MenuItem value={''}>全部分类</MenuItem>
                {data.map((item) => <MenuItem value={item.name}>{item.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

function Header({ numSelected, submit, loading = false }) {

    const LoginSchema = Yup.object().shape({
        Status: Yup.string().nullable(),
        Key: Yup.string().nullable()
    });

    const formik = useFormik({
        initialValues: {
            Status: '',
            Key: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            console.log('values', values);
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Stack direction={'row'} spacing={2}>
                                <CSelect
                                    labelId='Status'
                                    label='状态'
                                    source='/Public/GetEnumValsByGet?EnumName=models.Product.PropertyOrderStatus'
                                    {...getFieldProps('Status')}
                                />
                                <TextField
                                    type="text"
                                    label="订单号/商品名称/商品名称/业主姓名/手机号/房号"
                                    {...getFieldProps('Key')}
                                />
                                <Stack flex={1}></Stack>
                            </Stack>

                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function Index() {
    const [setTools] = useOutletContext();
    const theme = useTheme(); console.log('theme', theme)

    const TABLE_HEAD = [
        { label: '序号', id: 'Num' },
        { label: '订单概要', id: 'GY' },
        { label: '会员/业主', id: 'User' },
        { label: '产品/服务', id: 'Product' },
        { label: '数量', id: 'Count' },
        { label: '订单金额', id: 'Price', },
        { label: '状态', id: 'Status', },
        { label: '操作', id: '', align: 'right' }
    ];

    useEffect(() => {
        setTools([
        ]);
    }, []);

    return (
        <Card>
            <Pager url='/Web/Office/ProductOrder/PropertyOrderList'>
                {({ pageData, handleChangePage, searchSubmit, loading }) => {
                    return (
                        <>
                            <Header numSelected={0} submit={searchSubmit} loading={loading} />
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                    <TableCell align={headCell.align ?? 'inherit'}>
                                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pageData.data.map((row, index) => {
                                                const { id, OrderNumber, PTitle, PSCode, PSName, PSPic, Count,
                                                    TotalIntegral, TotalPrice,
                                                    AddresseeName, AddresseeMobile, AddresseeQu, AddresseeAddress,
                                                    Status, CreateTime } = row;
                                                return (
                                                    <TableRow hover>
                                                        <TableCell align="left">{index + 1}</TableCell>
                                                        <TableCell align="left">
                                                            <Stack direction={'row'}>
                                                                <div><img width={46} src={PSPic} /></div>
                                                                <Stack>
                                                                    <div>{OrderNumber}</div>
                                                                    <div>{CreateTime}</div>
                                                                </Stack>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div>{`${AddresseeName}(${AddresseeMobile})[${AddresseeQu}]`}</div>
                                                            <div>{AddresseeAddress}</div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div>{PTitle}</div>
                                                            <div>{`[${PSCode}]${PSName}`}</div>
                                                        </TableCell>
                                                        <TableCell align="left">{Count}</TableCell>
                                                        <TableCell align="left">
                                                            <div>{`金额: ${TotalPrice}元`}</div>
                                                            {TotalIntegral > 0 && <div>{`积分: ${TotalIntegral}分`}</div>}
                                                        </TableCell>
                                                        <TableCell align="left">{Status}</TableCell>
                                                        <TableCell align="right">
                                                            <MoreMenu Sub={
                                                                [
                                                                    {
                                                                        label: '明细',
                                                                        icon: <Icon icon={editFill} width={24} height={24} />,
                                                                        to: `/dashboard/product/order/detail/${id}`
                                                                    },
                                                                ]
                                                            } />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={-1}
                                component="div"
                                count={pageData.totalCount}
                                rowsPerPage={pageData.pageSize}
                                page={pageData.pageIndex}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )
                }}
            </Pager>
        </Card >
    );
}
